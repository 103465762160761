import logger from "@/services/logger";
import axios from "axios";
import heic2any from "heic2any";

const BASE_HOST =
  process.env.VUE_APP_FILE_API_URL || process.env.VUE_APP_API_URL || "";
const BASE_URL = `${BASE_HOST}/api/v1/buy`;

export async function uploadFile(file) {
  const uploadUrlResponse = await getUploadPresignedUrl(file.name);

  const uploadUrl = uploadUrlResponse["upload_pre_signed_url"];
  const fileS3Key = uploadUrlResponse["file_s3_key"];
  try {
    await axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      skipAuthToken: true,
    });
  } catch (error) {
    logger.error("Error uploading file to S3:", error);
    throw new Error("Failed to upload file to S3");
  }

  let finalUrl;
  try {
    finalUrl = await getFinalPresignedUrl(fileS3Key);
  } catch (error) {
    logger.error("Error getting final presigned URL:", error);
    throw new Error("Failed to get final presigned URL");
  }

  return finalUrl;
}

async function getUploadPresignedUrl(fileName) {
  const url = `${BASE_URL}/generate_upload_url/${fileName}`;

  const response = await axios.get(url);
  return response.data;
}

async function getFinalPresignedUrl(fileS3Key) {
  const url = `${BASE_URL}/generate_pre_signed_url?file_s3_key=${fileS3Key}`;

  const response = await axios.get(url);
  return response.data["pre_signed_url"];
}

export async function processFile(file) {
  if (file.name.endsWith(".heic") || file.type === "image/heic") {
    const convertedBlob = await heic2any({
      blob: file,
      toType: "image/jpeg",
    });
    file = new File([convertedBlob], file.name.replace(".heic", ".jpeg"), {
      type: "image/jpeg",
    });
  }

  const selectedImage = URL.createObjectURL(file);
  return { file, selectedImage };
}
