<template>
  <div v-scroll-to-element class="relative z-0 scroll-mt-32 bg-white">
    <!-- Hero Section -->
    <section class="body-font relative overflow-hidden text-gray-600">
      <!-- SVG Background -->
      <img
        class="absolute inset-x-0 bottom-0 z-0 w-full object-cover"
        style="max-height: 500px"
        alt="background"
        src="@/assets/olx-magic-wave-2.svg"
      />
      <div
        class="container relative z-10 mx-auto flex flex-col items-center px-5 py-24 pb-36 md:flex-row md:pb-32 lg:pb-44"
      >
        <div
          class="z-10 flex flex-col items-center text-center md:w-1/2 md:items-start md:text-left lg:grow"
        >
          <h1
            class="title-font mb-4 text-3xl font-bold text-gray-900 sm:text-4xl"
          >
            {{ $t("header.title") }}
          </h1>
          <p class="mb-6 leading-relaxed">
            {{ $t("header.subtitle") }}
          </p>
          <div class="flex justify-center">
            <button
              v-track:click="MAGIC_START_NOW"
              class="inline-flex rounded border-0 bg-purple-500 px-6 py-2 text-lg text-white hover:bg-purple-600 focus:outline-none"
              @click="getStarted"
            >
              {{ $t("header.headerbtn.text") }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- Features Section -->
    <section class="body-font text-gray-600">
      <div class="container mx-auto px-5 pb-12 pt-6">
        <div class="mb-12 text-center">
          <h2
            class="title-font mb-4 text-2xl font-medium text-gray-900 sm:text-3xl"
          >
            {{ $t("features.title") }}
          </h2>
          <p class="mx-auto text-base leading-relaxed lg:w-3/4 xl:w-2/4">
            {{ $t("features.subtitle") }}
          </p>
        </div>
        <div class="-m-4 flex flex-wrap">
          <!-- Feature 1: AI-Powered Voice Assistance -->
          <div class="w-full p-4 md:w-1/2">
            <div class="h-full rounded-xl bg-white p-6 shadow-lg">
              <VIcon
                class="mb-3 inline-block size-8 text-green-500"
                name="ri-voiceprint-fill"
              />
              <h3 class="title-font mb-2 text-lg font-medium text-gray-900">
                {{ $t("features.feature1.title") }}
              </h3>
              <p class="mb-4 text-base leading-relaxed">
                {{ $t("features.feature1.subtitle") }}
              </p>
              <ul class="list-inside list-disc text-gray-600">
                <li>{{ $t("features.feature1.exapmle1") }}</li>
                <li>{{ $t("features.feature1.exapmle2") }}</li>
                <li>{{ $t("features.feature1.exapmle3") }}</li>
              </ul>
            </div>
          </div>
          <!-- Feature 2: Visual Search -->
          <div class="w-full p-4 md:w-1/2">
            <div class="h-full rounded-xl bg-white p-6 shadow-lg">
              <VIcon
                class="mb-3 inline-block size-8 text-green-500"
                name="fa-regular-eye"
              />
              <h3 class="title-font mb-2 text-lg font-medium text-gray-900">
                {{ $t("features.feature2.title") }}
              </h3>
              <p class="mb-4 text-base leading-relaxed">
                {{ $t("features.feature2.subtitle") }}
              </p>
              <ul class="list-inside list-disc text-gray-600">
                <li>{{ $t("features.feature2.exapmle1") }}</li>
                <li>{{ $t("features.feature2.exapmle2") }}</li>
                <li>{{ $t("features.feature2.exapmle3") }}</li>
              </ul>
            </div>
          </div>
          <!-- Feature 3: Web Search -->
          <div class="w-full p-4 md:w-1/2">
            <div class="h-full rounded-xl bg-white p-6 shadow-lg">
              <VIcon
                class="mb-3 inline-block size-8 text-green-500"
                name="bi-search"
              />
              <h3 class="title-font mb-2 text-lg font-medium text-gray-900">
                {{ $t("features.feature3.title") }}
              </h3>
              <p class="mb-4 text-base leading-relaxed">
                {{ $t("features.feature3.subtitle") }}
              </p>
              <ul class="list-inside list-disc text-gray-600">
                <li>{{ $t("features.feature3.exapmle1") }}</li>
                <li>{{ $t("features.feature3.exapmle2") }}</li>
                <li>{{ $t("features.feature3.exapmle3") }}</li>
              </ul>
            </div>
          </div>
          <!-- Feature 4: Brainstorming with the Assistant -->
          <div class="w-full p-4 md:w-1/2">
            <div class="h-full rounded-xl bg-white p-6 shadow-lg">
              <VIcon
                class="mb-3 inline-block size-8 text-green-500"
                name="gi-brainstorm"
              />
              <h3 class="title-font mb-2 text-lg font-medium text-gray-900">
                {{ $t("features.feature4.title") }}
              </h3>
              <p class="mb-4 text-base leading-relaxed">
                {{ $t("features.feature4.subtitle") }}
              </p>
              <ul class="list-inside list-disc text-gray-600">
                <li>{{ $t("features.feature4.exapmle1") }}</li>
                <li>{{ $t("features.feature4.exapmle2") }}</li>
                <li>{{ $t("features.feature4.exapmle3") }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <footer class="border-t border-gray-200 bg-white py-4">
        <div class="container mx-auto flex items-center justify-between">
          <div class="flex flex-col space-y-2">
            <RouterLink
              to="/terms-conditions"
              target="_blank"
              class="text-primary text-sm font-medium hover:underline"
            >
              {{ $t("magic_search_two_point.policies.terms") }}
            </RouterLink>
            <RouterLink
              to="/privacy"
              target="_blank"
              class="text-primary text-sm font-medium hover:underline"
            >
              {{ $t("magic_search_two_point.policies.privacy") }}
            </RouterLink>
            <RouterLink
              to="/cookies"
              target="_blank"
              class="text-primary text-sm font-medium hover:underline"
            >
              {{ $t("magic_search_two_point.policies.cookie") }}
            </RouterLink>
          </div>
          <div class="flex flex-col">
            <span class="text-sm text-gray-700">
              {{ $t("navbar.download") }} :</span
            >
            <div class="flex">
              <a :href="playStoreLink" target="_blank" class="mr-2">
                <img
                  src="@/assets/icons/google-play-badge.svg"
                  alt="Google Play"
                  class="h-10"
                />
              </a>
              <a :href="appStoreLink" target="_blank">
                <img
                  src="@/assets/icons/app-store-badge.svg"
                  alt="App Store"
                  class="h-10"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { MAGIC_HOMEPAGE, MAGIC_START_NOW } from "@/constants/eventsConstants";
import TrackingService from "../services/TrackingService";
import logger from "@/services/logger";
import { getMagicToken } from "@/auth/magic_auth";
import {
  VUE_APP_APP_STORE_LINK,
  VUE_APP_PLAY_STORE_LINK,
} from "@/constants/storeLinks";

function isLoginRedirect() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.has("code") && queryParams.has("state");
}

export default {
  name: "HomePage",
  data() {
    return {
      MAGIC_START_NOW,
      playStoreLink: VUE_APP_PLAY_STORE_LINK,
      appStoreLink: VUE_APP_APP_STORE_LINK,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$auth0.isAuthenticated.value;
    },
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      async handler(isAuthenticated) {
        if (!isLoginRedirect()) return;

        if (!isAuthenticated) {
          try {
            await this.$auth0.handleRedirectCallback(window.location.href);
          } catch (e) {
            // TODO keep till we know how to handle these
            if (e.message === "Invalid State") {
              logger.warn("Invalid state error");
            } else if (e.message === "Missing state") {
              logger.warn("Missing state error");
            }
            logger.error("Error handling redirect callback");
          } finally {
            this.forceUpdateAuthStatus();
          }
        } else {
          this.$nextTick(() => this.$router.push("/buy"));
        }
      },
    },
  },
  async mounted() {
    TrackingService.trackPage(MAGIC_HOMEPAGE);
  },
  methods: {
    getStarted() {
      if (this.isAuthenticated || getMagicToken()) this.$router.push("/buy");
      else this.$auth0.loginWithRedirect();
    },
    async forceUpdateAuthStatus() {
      try {
        await this.$auth0.checkSession();
      } catch (e) {
        logger.error("Error checking session", e);
      }
    },
  },
};
</script>

<style scoped>
/* Additional styles if needed */
.shadow-lg {
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
