<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.13254 0C7.13254 1.92373 6.44594 3.55432 5.05292 4.94951C3.65887 6.34572 1.98806 7.0771 0 7.1678V8.79859C1.98739 8.88925 3.65658 9.62 5.04974 11.0305L5.05292 11.0337C6.4446 12.4275 7.13254 14.0744 7.13254 16H8.8435C8.8435 14.7267 9.15746 13.57 9.78695 12.4831C10.4387 11.3879 11.305 10.5157 12.389 9.86851L12.3918 9.86683C13.4896 9.20579 14.6787 8.85929 15.9941 8.79856L16 7.16811C13.984 7.06166 12.3131 6.34169 10.9231 4.94951C9.5301 3.55432 8.8435 1.92373 8.8435 0H7.13254Z"
      fill="url(#paint0_linear_1122_640)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1122_640"
        x1="0"
        y1="8"
        x2="16"
        y2="8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#003E3C" />
        <stop offset="1" stop-color="#00A49F" />
      </linearGradient>
    </defs>
  </svg>
</template>
