<template>
  <Modal v-if="downloadAppVisible" :show-close-button="false">
    <div
      v-if="downloadAppVisible"
      :class="$twMerge('mx-auto max-w-md rounded-lg')"
    >
      <div class="mb-4 flex items-center justify-between">
        <div class="flex items-center">
          <img
            src="@/assets/icons/olx-magic-logo.svg"
            alt="OLX Magic Logo"
            class="size-10"
          />
        </div>
        <button class="text-gray-500 hover:text-gray-700">
          <img
            v-track:click="
              isMagicHybrid()
                ? $EVENTS?.HYBRID?.MAGIC_DOWNLOAD_APP_CANCEL
                : $EVENTS?.CORE?.MAGIC_DOWNLOAD_APP_CANCEL
            "
            src="@/assets/icons/close-modal.svg"
            alt="close_button"
            class="size-6"
            @click="onClose"
          />
        </button>
      </div>

      <h1 class="mb-2 text-xl font-medium text-grey-700">
        {{ $t("app_download.title") }}
      </h1>
      <p class="mb-4 text-grey-700">
        {{ $t("app_download.subtitle") }}
      </p>

      <DownloadAppLinks />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/buy/conversation/Modal.vue";
import DownloadAppLinks from "@/components/download/DownloadAppLinks.vue";
import { mapGetters, mapMutations } from "vuex";
import { isSearchRoute } from "../product-page/utils";

export default {
  name: "DownloadAppModal",
  components: {
    DownloadAppLinks,
    Modal,
  },
  computed: {
    ...mapGetters("floatingItems", ["downloadAppVisible"]),
  },
  methods: {
    ...mapMutations("floatingItems", ["SET_DOWNLOAD_APP_VISIBLE"]),
    onClose() {
      this.SET_DOWNLOAD_APP_VISIBLE(false);
    },
    isMagicHybrid() {
      return isSearchRoute();
    },
  },
};
</script>
