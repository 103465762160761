import { getAccessToken } from "@/auth";
import { handleCancelableRequest } from "@/services/abortControllersService";
import { safeParseJSON } from "@/utils/object";

/**
 * Streaming request via fetch API
 * @param {*} url
 * @param {*} data to be passed to fetch
 */
export async function stream(
  url,
  { method, body, headers, cancelable, cancelableKey, cancelableNamespace },
  onMessage,
) {
  const signal = cancelable
    ? handleCancelableRequest(cancelableKey ?? url, method, cancelableNamespace)
    : undefined;

  const token = await getAccessToken();
  const response = await fetch(url, {
    method,
    body,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "text/event-stream",
      ...headers,
    },
    duplex: "half",
    credentials: "include",
    signal,
  });

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let isStreaming = true;

  while (isStreaming && (signal ? !signal.aborted : true)) {
    const { done, value } = await reader.read();
    if (done) {
      isStreaming = false;
      break;
    }
    const chunk = decoder.decode(value, { stream: true });
    onMessage(chunk);
  }
}

/**
 * Safely parses message with respect to message separator.
 * Calls messageHandler for each message inbetween separators.
 * Returns remaining buffer if message is incomplete.
 * @param {string} chunk
 * @param {funciton} messageHandler
 * @param {string} messageSeparator
 * @returns remaining buffer from chunk with messageSeparator added
 */
export function chunkHandler(chunk, messageHandler, messageSeparator = null) {
  const parts = (
    messageSeparator ? chunk.split(messageSeparator) : [chunk]
  ).filter((v) => !!v);

  for (let i = 0; i < parts.length; i++) {
    const message = parts[i];
    const data = safeParseJSON(message);
    if (data) messageHandler(data);
    else {
      const remainingBuffer = parts.slice(i).join(messageSeparator);
      return remainingBuffer;
    }
  }

  return "" + messageSeparator;
}
