<template>
  <MarkdownComponent
    v-for="(child, index) in vdomChildren"
    :key="index"
    :node="child"
  />
</template>

<script>
import { sanitizeHTML } from "@/utils/string";
import { marked } from "marked";
import { createVirtualDOM } from "../../utils/dom";
import MarkdownComponent from "./MarkdownComponent.vue";

export default {
  name: "Markdown",
  components: {
    MarkdownComponent,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      vdom: null,
    };
  },
  computed: {
    vdomChildren() {
      const arr = this.vdom?.children ?? [];
      return removeLastBRs(arr);
    },
  },
  watch: {
    text: {
      immediate: true,
      handler(next) {
        const htmlContent = marked(next);
        const sanitizedHtml = sanitizeHTML(htmlContent);
        this.vdom = createVirtualDOM(sanitizedHtml);
      },
    },
  },
};

function removeLastBRs(vdom) {
  const arr = vdom ?? [];
  while (arr.length > 0 && arr[arr.length - 1].tagName === "BR") arr.pop();

  for (const elm of arr) {
    const ch = elm?.children ?? [];
    while (ch.length > 0 && ch[ch.length - 1].tagName === "BR") ch.pop();
  }

  return arr;
}
</script>
