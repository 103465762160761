import { setModuleToPersist } from "../persistance/persisted-modules";

const MODULE_KEY = "settings";
setModuleToPersist(MODULE_KEY);

const state = {
  selectedLocale: "PL",
};

const mutations = {
  SET_LOCALE(state, locale) {
    state.selectedLocale = locale;
  },
  LOAD_DATA(state, data) {
    Object.assign(state, data);
  },
};

const actions = {
  setLocale({ commit }, locale) {
    commit("SET_LOCALE", locale);
  },
};

const getters = {
  selectedLocale: (state) => state.selectedLocale,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
