<template>
  <div>
    <div
      v-if="description"
      :class="
        $twMerge(
          'mt-4 flex items-center bg-white',
          'p-4 text-xl font-bold text-grey-600',
        )
      "
    >
      <span>{{ $t("product.description_label") }}</span>
      <img
        class="mx-2 h-6 w-auto"
        src="@/assets/magic_logo.png"
        alt="OLX Magic"
      />
    </div>

    <div v-if="loading" class="flex size-full flex-col gap-4 pt-4">
      <div class="h-16 w-full animate-pulse bg-slate-300"></div>
      <div class="h-32 w-full animate-pulse bg-slate-300"></div>
      <div class="h-16 w-full animate-pulse bg-slate-300"></div>
      <div class="h-28 w-full animate-pulse bg-slate-300"></div>
    </div>

    <div
      v-if="description && !loading"
      :class="
        $twMerge(
          'relative flex min-h-96 grow flex-col',
          'border-t-2 border-slate-300 bg-white px-4 pb-4',
        )
      "
    >
      <div class="message-text overflow-auto bg-white">
        <Markdown :text="description" />
      </div>

      <div
        v-if="showFeedback && description.length > 100"
        :class="
          $twMerge(
            'mt-auto flex items-center gap-2 rounded-b-md',
            'border-t-2 border-slate-300 bg-white pt-4 md:p-4',
          )
        "
      >
        <span>
          {{ $t("product.useful_feedback") }}
        </span>
        <img
          alt="thumbs-up-icon"
          :class="
            $twMerge(
              '!m-0 size-6 cursor-pointer !rounded-full border-transparent',
              'transition-transform hover:scale-110',
            )
          "
          src="@/assets/icons/thumbs_up.svg"
          @click.stop.prevent="feedback(true)"
        />
        <img
          alt="thumbs-up-icon"
          :class="
            $twMerge(
              '!m-0 size-6 cursor-pointer !rounded-full',
              'border-transparent transition-transform hover:scale-110',
            )
          "
          src="@/assets/icons/thumbs_down.svg"
          @click.stop.prevent="feedback(false)"
        />
      </div>

      <div
        v-if="feedBackThanks"
        :class="
          $twMerge(
            'mt-auto flex items-center gap-2 rounded-b-md',
            'border-t-2 border-slate-300 bg-white pt-4 md:p-4',
          )
        "
      >
        {{ $t("product.feedback_thanks") }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  postAdChatCompletionFeedback,
  getAdChatCompletionStream,
} from "@/services/AdsService";
import Markdown from "../ui/Markdown.vue";
import logger from "@/services/logger";
import { isFromMagicHybrid } from "./utils";

export default {
  name: "ProductDescription",
  components: {
    Markdown,
  },
  data() {
    return {
      description: null,
      loading: true,
      error: null,
      feedBackThanks: false,
      feedBackThanksTimer: null,
      showFeedback: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    language() {
      const lang = this.$route.query.lang ?? this.$i18n.locale;
      const langMap = {
        en: "english",
        pl: "polish",
      };
      return langMap[lang.toLowerCase()] ?? "polish";
    },
  },
  mounted() {
    this.fetchDescription();
  },
  beforeUnmount() {
    this.feedBackThanksTimer && clearTimeout(this.feedBackThanksTimer);
  },
  methods: {
    async fetchDescription() {
      try {
        this.loading = true;
        this.error = null;

        await getAdChatCompletionStream(
          this.id,
          (value) => {
            this.loading = false;
            const desc = `${this.description ?? ""}${value ?? ""}`;

            if (desc === "```") {
              this.description = desc;
              return;
            }

            this.description = desc
              .replace(/```markdown/g, "")
              .replace(/```$/g, "");
          },
          this.language,
        );
      } catch (e) {
        logger.error(e);
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    async feedback(useful) {
      const baseEvent = isFromMagicHybrid()
        ? this.$EVENTS.HYBRID
        : this.$EVENTS.CORE;
      const eventType = useful
        ? baseEvent.MAGIC_THUMBS_UP
        : baseEvent.MAGIC_THUMBS_DOWN;

      this.$tracking.trackEvent("click", eventType);
      postAdChatCompletionFeedback(this.id, {
        useful,
        description: this.description,
      });
      this.showFeedback = false;
      this.feedBackThanks = true;

      this.feedBackThanksTimer && clearTimeout(this.feedBackThanksTimer);
      this.feedBackThanksTimer = setTimeout(() => {
        this.feedBackThanks = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.message-text::v-deep(h1:not(:first-of-type)),
.message-text::v-deep(h2:not(:first-of-type)),
.message-text::v-deep(h3:not(:first-of-type)) {
  @apply !mt-8;
}
.message-text::v-deep(li) {
  @apply !list-disc ml-4;
}
</style>
