import { createAuth0Client } from "@olxeu/oauth2-spa-js";
import { ref } from "vue";
import logger from "@/services/logger";

let client;

export const AuthOLX = {
  _client: null,
  isAuthenticated: ref(false),
  isLoading: ref(true),
  user: ref(null),
  async install(app) {
    app.config.globalProperties.$auth0 = this;
    this.updateAuthStatus();
  },
  async getAccessToken() {
    const client = await getClient();
    return client.getTokenSilently();
  },
  async getAccessTokenSilently() {
    const client = await getClient();
    return client.getTokenSilently();
  },
  async loginWithRedirect() {
    const client = await getClient();
    return client.loginWithRedirect();
  },
  async handleRedirectCallback() {
    const client = await getClient();
    await client.handleRedirectCallback();
  },
  async checkSession() {
    this.updateAuthStatus();
  },
  async logout() {
    await getClient();
    return client.logout({
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      openUrl: async (url) => {
        window.location.assign(url);
      },
      logoutParams: {
        logout_uri: process.env.VUE_APP_BASE_URL,
      },
    });
  },
  async updateAuthStatus() {
    try {
      this.isLoading.value = true;
      const client = await getClient();
      await client.getTokenSilently();
      this.isAuthenticated.value = true;
      this.isLoading.value = false;
    } catch (e) {
      logger.error("Update auth status error", e);
      this.isAuthenticated.value = false;
      this.isLoading.value = false;
    }
  },
};

export async function authGuard() {
  try {
    const client = await getClient();
    const token = await client.getTokenSilently();
    return !!token;
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export async function getAccessToken() {
  const client = await getClient();
  return client.getTokenSilently();
}

async function getClient() {
  if (client) return client;
  client = await createAuth0Client({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    domainFallback: process.env.VUE_APP_AUTH0_DOMAIN_FALLBACK,
    issuer: process.env.VUE_APP_AUTH0_ISSUER,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    cookieDomain: process.env.VUE_APP_AUTH0_COOKIE_DOMAIN,
    useCookiesForTransactions: false,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: "localstorage",
    authorizationParams: {
      redirect_uri: process.env.VUE_APP_BASE_URL,
    },
  });
  return client;
}
