import store from "@/store";
import { createI18n } from "vue-i18n";
import EN from "./en.json";
import PL from "./pl.json";

export const i18n = createI18n({
  locale: store?.state.settings.selectedLocale || "PL",
  messages: {
    EN: EN,
    PL: PL,
  },
});
