<template>
  <component
    :is="tag"
    :href="href"
    :class="
      $twMerge(
        'flex justify-center items-center cursor-pointer gap-2 truncate',
        'h-9 px-3 py-2 shadow-custom leading-1 relative z-0 transition-all duration-300',

        floating ? 'rounded-full' : 'rounded-md',

        variant == 'primary' &&
          'text-white bg-gradient-to-r  from-magic-700 to-magic-600',
        variant == 'primary-dark' &&
          'text-white bg-gradient-to-r  from-magic-900 to-magic-800',
        variant == 'primary-light' &&
          'text-magic-950 bg-gradient-to-r  from-magic-500 to-magic-300',

        variant == 'secondary' &&
          'bg-transparent border-1 border-magic-900 text-magic-800',

        variant.startsWith('icon') && 'size-6 p-0 shadow-none',
        variant == 'icon-light' && 'bg-transparent',

        variant === 'error' &&
          ' text-red-950 bg-gradient-to-r from-red-400 to-red-300',
        disabled && 'cursor-not-allowed grayscale opacity-85',
      )
    "
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      required: false,
      default: "primary-dark",
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    floating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    tag() {
      return this.href ? "a" : "button";
    },
  },
};
</script>
