<template>
  <div v-if="loading" class="w-full">
    <div class="image-wrapper w-full animate-pulse bg-slate-300"></div>
    <div class="mt-4 flex w-full flex-row gap-4">
      <div class="size-28 animate-pulse bg-slate-300"></div>
      <div class="size-28 animate-pulse bg-slate-300"></div>
      <div class="size-28 animate-pulse bg-slate-300"></div>
    </div>
  </div>
  <div v-else class="w-full">
    <div class="image-wrapper relative w-full overflow-hidden">
      <Transition :name="transition">
        <BaseImage
          ref="activeImage"
          :key="activeImage"
          :src="activeImage"
          :class="
            $twMerge(
              'absolute left-0 top-0 size-full',
              'bg-white object-contain transition-all',
            )
          "
          @click="onClickActive($event)"
        />
      </Transition>
    </div>
    <div
      ref="images"
      class="olx-horizontal-scrollbar mt-4 flex w-full flex-row gap-4 overflow-x-auto pb-2"
    >
      <BaseImage
        v-for="(src, index) in images"
        :key="src"
        :src="src"
        class="size-28 shrink-0 bg-white object-cover transition-all"
        :class="{ 'border-2 border-teal-800': index === active }"
        @click="set(index)"
      />
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";
import BaseImage from "../ui/Image.vue";
import logger from "@/services/logger";

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

export default {
  name: "ProductGallery",
  components: {
    BaseImage,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: 0,
      transition: "slide-left",
    };
  },
  computed: {
    activeImage() {
      return this.images[this.active];
    },
    maxIndex() {
      return this.images.length - 1;
    },
  },
  watch: {
    active() {
      const activeElm = this.$refs.images.children[this.active];
      this.$nextTick(() => {
        activeElm?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initHammer();
    });
  },
  beforeUnmount() {
    this.destroyHammer();
  },
  methods: {
    initHammer() {
      const element = this.$refs.activeImage;
      if (element) {
        this.hammer = new Hammer(element);
        this.hammer.on("swipeleft", this.handleSwipeLeft);
        this.hammer.on("swiperight", this.handleSwipeRight);
      } else {
        logger.error("activeImage element not found");
      }
    },
    destroyHammer() {
      if (this.hammer) {
        this.hammer.off("swipeleft", this.handleSwipeLeft);
        this.hammer.off("swiperight", this.handleSwipeRight);
        this.hammer.destroy();
        this.hammer = null;
      }
    },
    handleSwipeLeft() {
      this.next();
    },
    handleSwipeRight() {
      this.prev();
    },
    next() {
      this.set(this.clamp(this.active + 1));
    },
    prev() {
      this.set(this.clamp(this.active - 1));
    },
    _set(index) {
      this.transition = index > this.active ? "slide-left" : "slide-right";
      this.active = this.clamp(index);
    },
    set(index) {
      this._set(index);
    },
    clamp(value) {
      return clamp(value % (this.maxIndex + 1), 0, this.maxIndex);
    },
    onClickActive(event) {
      const { x, width } = this.$refs.activeImage.getBoundingClientRect();
      const offset = event.clientX - x;
      if (offset < width / 2) {
        this.prev();
      } else {
        this.next();
      }
    },
  },
};
</script>

<style scoped>
.image-wrapper {
  height: calc(3 * theme("height.36"));
}
</style>
