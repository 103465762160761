<template>
  <BaseButton
    v-track:click="$EVENTS?.HYBRID?.MAGIC_ATTACHMENT_CLICK"
    class="rounded sm:hover:bg-gray-300"
    :class="{
      'animate-pulse opacity-50 sm:hover:bg-white': loading,
      'sm:hover:bg-gray-300': !loading,
    }"
    variant="icon"
    @click="onFileClick"
  >
    <CameraIcon class="size-5 text-grey-700" />
    <input
      ref="fileInput"
      type="file"
      accept=".jpeg, .jpg, .heic, .png, .webp"
      class="hidden"
      @change="handleFileUpload"
    />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { processFile, uploadFile } from "@/services/FileUploadService";
import logger from "@/services/logger";
import { mapActions, mapMutations } from "vuex";
import CameraIcon from "@/components/ui/icons/CameraIcon.vue";

export default {
  name: "SearchBarImageUploader",
  components: {
    BaseButton,
    CameraIcon,
  },
  emits: ["fileChange", "fileWillRead"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions("search", ["search"]),
    ...mapMutations("search", ["SET_UPLOADED_IMAGE", "CLEAR_FILE_UPLOAD"]),
    ...mapMutations("hybridHelper", ["SET_MAIN_ERROR"]),
    onFileClick() {
      if (!this.loading) {
        this.triggerFileUpload();
      }
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.SET_MAIN_ERROR(null);

      this.loading = true;
      this.CLEAR_FILE_UPLOAD();
      this._readFile(file);

      try {
        const { file: processedFile } = await processFile(file);
        const fileUrl = await uploadFile(processedFile);
        this.SET_UPLOADED_IMAGE(fileUrl);
      } catch (error) {
        logger.error("Error uploading file:", error);
        this.SET_MAIN_ERROR(this.$t("search.helper.file_error"));
        this.CLEAR_FILE_UPLOAD();
      } finally {
        event.target.value = null;
        this.loading = false;
      }
    },
    _readFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$emit("fileChange", e.target.result);
        this.$emit("fileWillRead", false);
      };
      reader.onerror = () => {
        this.$emit("fileChange", null);
        this.$emit("fileWillRead", false);
      };
      this.$emit("fileWillRead", true);
      reader.readAsDataURL(file);
    },
  },
};
</script>
