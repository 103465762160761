<template>
  <div
    :class="
      $twMerge(
        'flex items-center px-3 rounded overflow-hidden h-8',
        'bg-magic-100 border border-magic-400 border-collapse',
        'text-magic-900 font-normal text-sm leading-3 select-none ',
      )
    "
  >
    <MagicSearchIcon v-if="icon === 'search'" class="mr-2 size-5 shrink-0" />
    <CameraIcon v-else-if="icon === 'image'" class="mr-2 size-5 shrink-0" />
    <FilterIcon v-else class="mr-2 shrink-0" />

    <span class="mr-2 mt-1 line-clamp-1 text-ellipsis capitalize leading-4">
      <slot></slot>
    </span>
    <div class="ml-auto flex shrink-0 items-center gap-2">
      <slot name="image-preview"></slot>
      <DeleteIcon class="shrink-0 cursor-pointer" @click="onRemoveTag" />
    </div>
  </div>
</template>

<script>
import FilterIcon from "@/components/ui/icons/FilterIcon.vue";
import DeleteIcon from "@/components/ui/icons/DeleteIcon.vue";
import MagicSearchIcon from "@/components/ui/icons/MagicSearchIcon.vue";
import CameraIcon from "@/components/ui/icons/CameraIcon.vue";

export default {
  name: "SearchTag",
  components: {
    FilterIcon,
    DeleteIcon,
    MagicSearchIcon,
    CameraIcon,
  },
  props: {
    icon: {
      type: String,
      default: "filter",
    },
  },
  emits: ["remove"],
  methods: {
    onRemoveTag() {
      this.$emit("remove");
    },
  },
};
</script>
