import { ONBOARDING_TYPE } from "@/constants/onboardingConstants";

export default {
  namespaced: true,
  state: {
    downloadAppVisible: false,
    onboardingTipVisible: Object.values(ONBOARDING_TYPE).reduce(
      (acc, type) => ({ ...acc, [type]: false }),
      {},
    ),
  },
  mutations: {
    SET_DOWNLOAD_APP_VISIBLE(state, visible) {
      state.downloadAppVisible = visible;
    },
    SET_ONBOARDING_TIP_VISIBLE(state, { onboardingType, value }) {
      state.onboardingTipVisible[onboardingType] = value;
    },
  },
  actions: {
    showOnboardingTip({ commit }, { onboardingType }) {
      commit("SET_ONBOARDING_TIP_VISIBLE", { onboardingType, value: true });

      setTimeout(() => {
        commit("SET_ONBOARDING_TIP_VISIBLE", { onboardingType, value: false });
      }, 10000);
    },
    hideOnboardingTip({ commit }, { onboardingType }) {
      commit("SET_ONBOARDING_TIP_VISIBLE", { onboardingType, value: false });
    },
  },
  getters: {
    downloadAppVisible: (state) => state.downloadAppVisible,
    onboardingTipVisible: (state) => state.onboardingTipVisible,
  },
};
