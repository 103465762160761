import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mutations: {
    setWindowWidth(state, width) {
      state.windowWidth = width;
    },
  },
});

let resizeTimeout;

window.addEventListener("resize", () => {
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(() => {
    store.commit("setWindowWidth", window.innerWidth);
  }, 400);
});

export default store;
