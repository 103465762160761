import axios from "axios";

const BASE_PATH = `${process.env.VUE_APP_API_URL ?? ""}/api/v1/users`;

export async function getUsersMe() {
  const url = `${BASE_PATH}/me`;

  const response = await axios.get(url);
  return response.data;
}
