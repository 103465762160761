<template>
  <div class="flex size-6 items-center justify-center rounded">
    <input
      :id="id"
      type="checkbox"
      class="hidden"
      :checked="checked"
      :disabled="disabled"
      @change="handleChange"
    />
    <label
      :for="id"
      :class="[
        'custom-checkbox size-full',
        { 'cursor-not-allowed opacity-75': disabled },
      ]"
      class="size-6 rounded"
      @click.stop.prevent="handleChange"
    ></label>
  </div>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    defaultChecked: {
      type: Boolean,
      default: false,
    },
    checkboxClass: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  data() {
    return {
      internalChecked: this.defaultChecked,
    };
  },
  watch: {
    checked(newVal) {
      this.internalChecked = newVal;
    },
  },
  methods: {
    handleChange() {
      this.internalChecked = !this.internalChecked;
      this.$emit("change", this.internalChecked);
    },
  },
};
</script>

<style>
.custom-checkbox {
  position: relative;
  background-color: white;
  border: 2px solid #1dbfb6;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 44%;
  left: 50%;
  width: 5px;
  height: 10px;
  border: 2px solid transparent;
  border-width: 0 0.125rem 0.125rem 0;
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transition:
    transform 0.2s ease,
    border-color 0.2s ease;
}

input:checked + label.custom-checkbox {
  background-color: #179992;
  border-color: #179992;
}

input:checked + label.custom-checkbox::before {
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
  border-color: white;
}

input:not(:checked) + label.custom-checkbox::before {
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
  border-color: #48e9e1;
}
</style>
