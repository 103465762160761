<template>
  <div class="bg-white p-6">
    <h2
      :class="
        $twMerge(
          'mb-6 hidden border-b border-gray-300 pb-4',
          'text-left text-2xl font-medium sm:block',
        )
      "
    >
      {{ $t("settings.title") }}
    </h2>
    <div class="space-y-6">
      <div class="text-left">
        <label class="mr-2 text-left"> {{ $t("settings.language") }} : </label>
        <div class="inline-flex items-center">
          <input
            id="en"
            v-model="localSelectedLocale"
            type="radio"
            value="EN"
            class="mx-1"
            @change="applySettings"
          />
          <label class="ml-2" for="en"> {{ $t("settings.english") }} </label>
        </div>
        <div class="ml-4 inline-flex items-center">
          <input
            id="pl"
            v-model="localSelectedLocale"
            type="radio"
            value="PL"
            class="mx-1"
            @change="applySettings"
          />
          <label class="ml-2" for="pl"> {{ $t("settings.polish") }} </label>
        </div>
      </div>

      <div class="h-6 text-green-600">
        <p v-if="successMessage">
          {{ successMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ref } from "vue";
import { MAGIC_MY_OLX_SETTINGS_APPLY } from "@/constants/eventsConstants";

export default {
  name: "Settings",
  emits: ["button-click"],
  setup() {
    const localSelectedLocale = ref("");

    return { localSelectedLocale };
  },
  data() {
    return {
      successMessage: "",
    };
  },
  computed: {
    ...mapState("settings", ["selectedLocale"]),
    screenWidth() {
      return this.$store.state.windowSize.windowWidth;
    },
  },
  watch: {
    selectedLocale: {
      immediate: true,
      handler(newVal) {
        this.localSelectedLocale = newVal;
      },
    },
  },
  methods: {
    ...mapActions("settings", ["setLocale"]),
    applySettings() {
      this.$tracking.trackEvent("click", MAGIC_MY_OLX_SETTINGS_APPLY);
      this.setLocale(this.localSelectedLocale);

      this.successMessage = this.$t("settings.success_message");
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);

      if (this.screenWidth < 640) {
        setTimeout(() => {
          this.$emit("button-click");
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  position: absolute;
  opacity: 0;
}

input[type="radio"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

input[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #ddd;
  border-radius: 50%;
  background: white;
}

input[type="radio"]:checked + label:before {
  border-color: #002f34;
  background: #002f34;
}

input[type="radio"]:checked + label:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

label {
  @apply font-normal mb-2;
}

p {
  @apply mt-4 text-green-600;
}
</style>
