import TrackingService from "../services/TrackingService";
import logger from "@/services/logger";

const trackDirective = {
  mounted(el, binding) {
    const eventType = binding.arg;
    const eventData = binding.value;

    const eventHandler = (event) => {
      if (event.currentTarget === el) {
        if (typeof eventData === "object") {
          const { ...params } = eventData;
          TrackingService.trackEvent(eventType, { ...params });
        } else {
          logger.error(
            eventData,
            "v-track directive: Invalid event data format. Expected an object.",
          );
        }
      }
    };

    el.__trackHandler__ = eventHandler;

    el.addEventListener(eventType, eventHandler, true);
  },
  unmounted(el, binding) {
    const eventType = binding.arg;

    if (el.__trackHandler__) {
      el.removeEventListener(eventType, el.__trackHandler__, true);
      delete el.__trackHandler__;
    }
  },
};

export { trackDirective };
