<template>
  <div v-if="nextSearchSuggestions?.length" class="px-1 text-sm">
    <MagicHelperBubble>
      <h3 class="flex items-center font-normal">
        <span>
          {{
            $t(
              "magic_search_two_point.search_page.next_search_suggestions_title",
            )
          }}
        </span>
      </h3>
      <p class="mb-1">
        {{
          $t("magic_search_two_point.search_page.next_search_suggestions_desc")
        }}
      </p>
      <div class="olx-horizontal-scrollbar flex gap-2 overflow-x-auto pb-2">
        <TransitionGroup name="fade" duration="300">
          <BaseButton
            v-for="(suggestion, index) in nextSearchSuggestions"
            :key="suggestion"
            v-track:click="$EVENTS?.HYBRID?.MAGIC_NEXT_SEARCH_SELECTED"
            :style="{ transitionDelay: `${index * 50}ms` }"
            variant="primary"
            class="shrink-0"
            @click.stop="onRefine(suggestion)"
          >
            {{ suggestion }}
          </BaseButton>
        </TransitionGroup>
      </div>
    </MagicHelperBubble>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { mapActions, mapGetters } from "vuex";
import MagicHelperBubble from "../helper/MagicHelperBubble.vue";

export default {
  name: "NextSearchSuggestions",
  components: {
    BaseButton,
    MagicHelperBubble,
  },
  computed: {
    ...mapGetters("search", ["nextSearchSuggestions"]),
  },
  methods: {
    ...mapActions("search", ["refine"]),
    ...mapActions("hybridActions", ["refine"]),
    onRefine(suggestion) {
      this.refine({ message: suggestion });
    },
  },
};
</script>
