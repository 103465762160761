<template>
  <BaseButton
    v-track:click="onBuy"
    variant="primary-dark"
    :href="`${product.url}?reason=magic&ad_reason=magic`"
    target="_blank"
    :disabled="product?.url === null"
  >
    {{ $t("product.buy_button") }}
  </BaseButton>
</template>

<script>
import BaseButton from "../ui/BaseButton.vue";
import { isFromMagicHybrid } from "./utils";

export default {
  name: "ProductButton",
  components: {
    BaseButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    onBuy() {
      const { id: ad_id } = this.product;
      const magic_ad_source = this.$route.query.ad_source;
      const magic_ad_tag = Number(this.$route.query.ad_tag);
      const { HYBRID, CORE } = this.$EVENTS || {};

      const eventDetails = isFromMagicHybrid()
        ? { ...HYBRID?.MAGIC_AD_LINK, magic_ad_tag }
        : CORE?.MAGIC_AD_LINK;

      return {
        ...eventDetails,
        ad_id,
        magic_ad_source,
      };
    },
  },
};
</script>
