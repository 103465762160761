<template>
  <div
    v-if="visible"
    :class="
      $twMerge(
        'fixed inset-0 z-50 flex items-end justify-center',
        'bg-black/50 backdrop-blur-xs',
      )
    "
    @click="handleBackdropClick"
  >
    <div
      ref="bottomSheet"
      class="h-4/5 w-full rounded-t-2xl bg-white p-4 shadow-lg"
      @click.stop
    >
      <div
        :class="
          $twMerge(
            'flex items-center justify-between',
            'border-b border-gray-300 pb-4',
          )
        "
      >
        <div :class="$twMerge('text-left text-2xl font-medium')">
          {{ heading }}
        </div>
        <button
          :class="
            $twMerge(
              'flex size-9 items-center justify-center',
              'rounded-lg hover:bg-gray-200',
            )
          "
          @click="close"
        >
          <img
            :class="$twMerge('size-6')"
            src="@/assets/icons/close.svg"
            alt="close-icon"
          />
        </button>
      </div>
      <slot></slot>
      <div v-if="buttonText" class="flex justify-center">
        <button
          :class="$twMerge('rounded-lg bg-blue-500 px-4 py-2 text-white')"
          @click="onButtonClick"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["button-click", "close"],
  methods: {
    close() {
      this.$emit("close");
    },
    handleBackdropClick(event) {
      if (!this.$refs.bottomSheet.contains(event.target)) {
        this.close();
      }
    },
    onButtonClick() {
      this.$emit("button-click");
    },
  },
};
</script>

<style scoped>
/* Add any additional styling here */
</style>
