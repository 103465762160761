<template>
  <!-- Floating buttons and Selection Counter -->
  <div
    class="fixed bottom-4 right-1/2 z-40 mx-auto flex translate-x-1/2 flex-col items-center gap-2 md:right-32"
  >
    <!-- Button Container -->
    <div class="flex gap-3">
      <SelectedAdCounter class="md:hidden" />

      <!-- Refine Button -->
      <BaseButton
        variant="primary-dark"
        :floating="true"
        class="md:hidden"
        :disabled="!refineButtonEnabled"
        @click="onShowRefine"
      >
        <RefineIcon class="size-5" />
        <span class="text-sm font-medium">{{
          $t("magic_search_two_point.action_bar_button_text.filter")
        }}</span>
      </BaseButton>

      <!-- Smart Compare Button -->
      <BaseButton
        v-track:click="$EVENTS?.HYBRID?.MAGIC_SMART_COMPARE"
        variant="primary-dark"
        :floating="true"
        class="md:hidden"
        :disabled="selectedAdIds.length < 2"
        @click="onShowCompare"
      >
        <CompareIcon class="size-5" :is-disabled="selectedAdIds.length < 2" />
        <span class="text-sm font-medium">{{
          $t("magic_search_two_point.action_bar_button_text.compare")
        }}</span>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { mapGetters, mapMutations } from "vuex";
import CompareIcon from "./CompareIcon.vue";
import RefineIcon from "./RefineIcon.vue";
import SelectedAdCounter from "./SelectedAdCounter.vue";

export default {
  name: "FloatingActions",
  components: {
    SelectedAdCounter,
    BaseButton,
    CompareIcon,
    RefineIcon,
  },
  computed: {
    ...mapGetters("hybridActions", ["selectedAdIds"]),
    ...mapGetters("search", ["refineButtonEnabled"]),
  },
  methods: {
    ...mapMutations("hybridActions", [
      "SET_REFINE_MODAL_OPEN",
      "SET_COMPARE_MODAL_OPEN",
      "CLEAR_SELECTED_AD_IDS",
    ]),
    onShowRefine() {
      if (!this.refineButtonEnabled) return;
      this.SET_REFINE_MODAL_OPEN(true);
    },
    onShowCompare() {
      if (this.selectedAdIds.length < 2) return;
      this.SET_COMPARE_MODAL_OPEN(true);
    },
  },
};
</script>
