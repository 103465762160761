import WebSocketService from "@/services/WebSocketService";
import store from "@/store";
import {
  triggerVisualSearch,
  triggerMessageTrackingEvent,
} from "../utils/onboarding";
import logger from "@/services/logger";

export default {
  namespaced: true,
  state: {
    messages: [],
    followUpResponses: [],
    audioState: "play",
    status: "waiting",
    sessionId: null,
    task_status: "completed",
    new_session: false,
    chat_offsetY: 0,
    queue: [],
  },
  mutations: {
    SEND_MESSAGE(state, message) {
      const { text, filesUrls } = message;
      if (!text && (!filesUrls || filesUrls.length === 0)) {
        return;
      }
      state.messages.push({ ...message, timestamp: new Date() });
    },
    RECEIVE_MESSAGE(state, message) {
      state.messages.push({ ...message, timestamp: new Date() });
    },
    MARK_SENDING_MESSAGE_STATUS(state, status) {
      state.messages = state.messages.map((message) => {
        if (message.status === "sending") message.status = status;
        return message;
      });
    },
    APPEND_MESSAGE(state, message) {
      if (message.messageType === "text") {
        state.messages[state.messages.length - 1].text += message.text;
      } else if (message.messageType === "tool_call") {
        state.messages.push({ ...message, timestamp: new Date() });
      } else {
        logger.error("Unknown message type:", message.messageType);
      }
    },
    UPDATE_MESSAGE_FEEDBACK(state, { index, feedback }) {
      state.messages[index].feedback = feedback;
    },
    UPDATE_AUDIO_STATE(state, audioState) {
      state.audioState = audioState;
    },
    SET_FOLLOW_UP_RESPONSES(state, followUpResponses) {
      state.followUpResponses = followUpResponses;
    },
    CLEAR_FOLLOW_UP_RESPONSES(state) {
      state.followUpResponses = [];
    },
    CLEAR_MESSAGES(state) {
      state.messages = [];
    },
    SET_STATUS(state, status) {
      state.status = status;
    },
    SET_TASK_STATUS(state, task_status) {
      state.task_status = task_status;
    },
    SET_SESSION_ID(state, sessionId) {
      state.sessionId = sessionId;
    },
    SET_NEW_SESSION(state, new_session) {
      state.new_session = new_session;
    },
    SET_CHAT_OFFSET_Y(state, chat_offsetY) {
      state.chat_offsetY = chat_offsetY;
    },
    ADD_TO_QUEUE(state, message) {
      state.queue.push(message);
    },
  },
  actions: {
    receiveMessage({ state, commit }, message) {
      commit("CLEAR_FOLLOW_UP_RESPONSES");
      const lastMessage = state.messages[state.messages.length - 1];
      const isLastMessageFromBot = lastMessage?.isUser === false;
      const isLastMessageProductsList = lastMessage?.products;
      const isLastMessageTextOrFirstChunk =
        lastMessage?.messageType === "text" ||
        lastMessage?.messageType === "first_chunk";
      const isMessageTypeText = message?.messageType === "text";
      const isMessageTypeWSConnection =
        message?.messageType === "ws_connection";

      state.status = message.status;
      state.task_status = message.task_status;

      if (isMessageTypeWSConnection) {
        const wsConnectionStatus = message.text;
        const sessionId = message.sessionId;
        logger.log("WebSocket connection status:", wsConnectionStatus);
        logger.log("Session ID:", sessionId);
        if (wsConnectionStatus === "CONNECTED") {
          commit("SET_SESSION_ID", sessionId);
          state.queue.forEach(() => {
            triggerMessageTrackingEvent(state.sessionId);
          });
          state.queue = [];
          logger.log(
            "Connected to WebSocket and received session ID:",
            state.sessionId,
          );
        }
      }

      if (message.products) {
        commit("products/ADD_PRODUCTS", message.products, { root: true });
      }

      if (
        isLastMessageFromBot &&
        isLastMessageTextOrFirstChunk &&
        isMessageTypeText &&
        !isLastMessageProductsList &&
        !message.products
      ) {
        commit("APPEND_MESSAGE", message);
        return;
      }

      const isMessageTypeTextOrFirstChunkOrToolCall =
        isMessageTypeText ||
        message.messageType === "first_chunk" ||
        message.messageType === "tool_call";
      const isMessageNotTakePhoto = message.text !== "take_photo";

      if (isMessageTypeTextOrFirstChunkOrToolCall && isMessageNotTakePhoto) {
        commit("RECEIVE_MESSAGE", message);
        return;
      } else {
        logger.log("Other Message:", message);
      }

      if (message.messageType === "follow_up_questions" && message.options) {
        commit("SET_FOLLOW_UP_RESPONSES", message.options);
        logger.log("Follow up questions:", message.options);
        return;
      }

      // Just a status update
      if (message.status) return;
      if (message.task_status) return;

      logger.error(`Unsupported message: ${message.text}`);
    },
    updateMessageFeedback({ state, commit }, { message, feedback, type }) {
      let index = -1;
      if (type === "text_results") {
        index = state.messages.findIndex((msg) => msg.text === message);
      } else if (type === "search_results") {
        index = state.messages.findIndex((msg) => {
          if (!msg.searchResults) {
            return false;
          }
          return (
            msg.searchResults.count === message.count &&
            msg.searchResults.search_query === message.search_query &&
            msg.searchResults.results.length === message.results.length &&
            msg.searchResults.results.every((msgResult, index) => {
              return msgResult.title === message.results[index].title;
            })
          );
        });
      }
      if (index !== -1) {
        WebSocketService.sendMessage({
          value: feedback,
          messageType: "feedback",
          isUser: true,
        });
        commit("UPDATE_MESSAGE_FEEDBACK", { index, feedback });
      } else {
        logger.error("Message not found:", message, type);
      }
    },
    updateAudioState({ commit }, audioState) {
      commit("UPDATE_AUDIO_STATE", audioState);
    },
    stopGeneration({ dispatch }) {
      dispatch("sendMessage", { messageType: "stop_generation" });
    },
    async sendMessage({ state, commit }, message) {
      commit("SEND_MESSAGE", { ...message, status: "sending" });
      commit("CLEAR_FOLLOW_UP_RESPONSES");
      try {
        await WebSocketService.verifyConnection(state.sessionId);
        await WebSocketService.sendMessage(message);

        if (state.sessionId) triggerMessageTrackingEvent(state.sessionId);
        else commit("ADD_TO_QUEUE", message);

        commit("MARK_SENDING_MESSAGE_STATUS", "sent");
      } catch (e) {
        logger.error("Error sending message:", e);
        commit("MARK_SENDING_MESSAGE_STATUS", "failed");
      }
    },
    async sendStreamingTask({ state, commit }, message) {
      commit("SET_STATUS", "working");
      commit("SET_TASK_STATUS", "working");
      commit("CLEAR_FOLLOW_UP_RESPONSES");
      try {
        await WebSocketService.verifyConnection(state.sessionId);
        await WebSocketService.waitConnectedStatus();
        await WebSocketService.sendMessage(message);
        commit("MARK_SENDING_MESSAGE_STATUS", "sent");
      } catch (e) {
        logger.error("Error sending streaming task:", e);
        commit("MARK_SENDING_MESSAGE_STATUS", "failed");
      }
    },
    async createNewSession({ commit }) {
      commit("CLEAR_MESSAGES");
      commit("CLEAR_FOLLOW_UP_RESPONSES");
      commit("SET_SESSION_ID", null);
      commit("SET_STATUS", "waiting");
      commit("SET_NEW_SESSION", false);
      commit("SET_TASK_STATUS", "completed");
      store.dispatch("selected/clearSelected");
      store.dispatch("favourites/clearFavourites");

      triggerVisualSearch();

      WebSocketService.disconnect();
      await WebSocketService.connect();
    },
    handleSocketClose({ commit }) {
      commit("SET_STATUS", "waiting");
      commit("SET_TASK_STATUS", "completed");
    },
    updateChatOffsetY({ commit }, chat_offsetY) {
      commit("SET_CHAT_OFFSET_Y", chat_offsetY);
    },
    findSimilar({ commit, dispatch }, item) {
      dispatch("sendStreamingTask", {
        value: item,
        messageType: "find_similar",
        isUser: true,
      });
      commit("CLEAR_FOLLOW_UP_RESPONSES");
    },
  },
};
