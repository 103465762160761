<template>
  <div class="my-2 w-full border-y border-grey-400 bg-teal-200 px-4 py-2">
    <h2
      class="my-2 text-right text-base font-medium uppercase tracking-wider text-magic-800"
    >
      {{ title }} ({{ products.length }})
      <TitleLead class="mb-1 ml-1 inline" />
    </h2>
    <div
      class="olx-horizontal-scrollbar flex w-full gap-2 overflow-x-auto pb-2"
    >
      <ProductCard
        v-for="product in products"
        :key="product.id"
        class-name="shrink-0"
        variant="main"
        :product="product"
        :source="source"
      />
      <RouterLink
        v-if="source === 'query_based_group' && products?.length"
        v-track:click="$EVENTS.HYBRID.MAGIC_GROUP_SHOW_MORE"
        class="flex w-40 shrink-0 cursor-pointer flex-col overflow-hidden rounded-md bg-white"
        :to="{ name: 'Search', query: { q: title } }"
      >
        <div class="flex h-40 items-center justify-center bg-magic-300">
          <TitleLead class="size-16 text-magic-900" />
        </div>
        <div class="mx-2 mt-1.5 flex items-center gap-2 text-sm">
          <MagicSearchIcon class="mb-1 size-4 text-grey-700" />
          {{ $t("search.groups.explore_more") }}
        </div>
        <span
          class="mx-2 mb-2 mt-auto text-right text-xs font-normal text-magic-700 underline"
        >
          {{ title }}
        </span>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import ProductCard from "./ProductCard.vue";
import TitleLead from "@/components/ui/icons/TitleLead.vue";
import MagicSearchIcon from "@/components/ui/icons/MagicSearchIcon.vue";

export default {
  name: "ProductGroup",
  components: {
    ProductCard,
    TitleLead,
    MagicSearchIcon,
    RouterLink,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    source: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    onSearchMore() {
      this.$router.push({ name: "SearchPage", query: { q: this.title } });
    },
  },
};
</script>
