import { nextTick } from "vue";

export default {
  mounted(el) {
    nextTick(() => {
      if (el) {
        el.scrollIntoView({ behavior: "auto", block: "start" });
      }
    });
  },
};
