const isProduction = process.env.VUE_APP_STAGE === "production";

class Logger {
  log(message, ...optionalParams) {
    if (!isProduction) {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  }

  warn(message, ...optionalParams) {
    if (!isProduction) {
      // eslint-disable-next-line no-console
      console.warn(message, ...optionalParams);
    }
  }

  error(message, ...optionalParams) {
    if (!isProduction) {
      // eslint-disable-next-line no-console
      console.error(message, ...optionalParams);
    }
  }
}

const logger = new Logger();
export default logger;
