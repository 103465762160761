const storage = localStorage;

const PREFIX = "X-Magic-";
const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
const KEYS = {
  CLIENT_S: "client_s",
  CLIENT_SL: "client_sl",
  TOKEN: "token",
};

export async function completeEntryResponse(response) {
  reset();
  const { client_s, client_sl, token } = response;

  setItem(KEYS.CLIENT_S, client_s);
  setItem(KEYS.CLIENT_SL, client_sl);
  setItem(KEYS.TOKEN, token);

  return { client_s, client_sl };
}

export function getMagicToken() {
  return getItem(KEYS.TOKEN);
}

export function getClientSessionParams() {
  return {
    client_s: getItem(KEYS.CLIENT_S),
    client_sl: getItem(KEYS.CLIENT_SL),
  };
}

function getItem(key) {
  try {
    const str = storage.getItem(`${PREFIX}${key}`);
    const data = JSON.parse(str);
    const { expiry, value } = data;
    const now = new Date();
    if (now.getTime() > expiry) {
      removeItem(key);
      return null;
    }
    return value;
  } catch {
    removeItem(key);
    return null;
  }
}

function setItem(key, value) {
  const now = new Date();
  const data = { value, expiry: now.getTime() + ONE_WEEK };
  storage.setItem(`${PREFIX}${key}`, JSON.stringify(data));
}

function removeItem(key) {
  storage.removeItem(`${PREFIX}${key}`);
}

function reset() {
  Object.keys(KEYS).forEach((key) => {
    storage.removeItem(`${PREFIX}${key}`);
  });
}
