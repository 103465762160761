<!-- eslint-disable vue/max-len -->
<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99995 14L5.33328 14.6667L4.66662 14L4.66662 3.60963L2.27596 6.0003L1.33329 6.0003L1.33329 5.0573L4.86195 1.52862L5.80462 1.52862L9.33327 5.0573L9.33327 6.0003L8.39061 6.0003L5.99995 3.60963L5.99995 14Z"
    />
    <path
      d="M11.138 14.4714L10.195 14.4714L6.66631 10.9427L6.66631 10L7.60931 10L9.99964 12.3907L9.99964 1.99999L10.6666 1.33332L11.3333 1.99999L11.3333 12.3907L13.724 10L14.6666 10L14.6666 10.9427L11.138 14.4714Z"
    />
  </svg>
</template>
