import axios from "axios";

const BASE_PATH = `${process.env.VUE_APP_API_URL ?? ""}/api/v1/auth`;

export async function completeEntry(body) {
  const url = `${BASE_PATH}/complete-entry`;

  const response = await axios.post(url, body, { skipAuthToken: true });
  return response.data;
}

export async function completeEntryWeb(key) {
  const url = `${BASE_PATH}/complete-entry/web`;

  const response = await axios.post(url, { key }, { skipAuthToken: true });
  return response.data;
}

export async function verifyMagicToken(token) {
  const url = `${BASE_PATH}/verify-token`;

  const response = await axios.post(url, { token }, { skipAuthToken: true });
  return response.data;
}
