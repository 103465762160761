import { marked } from "marked";
import { createVirtualDOM } from "./dom";

const renderer = {
  heading(text, level) {
    return `<h${level} class="markdown-h${level}">${text}</h${level}>`;
  },
  paragraph(text) {
    return `<p class="markdown-p">${text}</p>`;
  },
  list(body, ordered, start) {
    const type = ordered ? "ol" : "ul";
    const startAttr = ordered && start !== 1 ? ` start="${start}"` : "";
    return `<${type}${startAttr} class="markdown-list markdown-${type}">${body}</${type}>`;
  },
  listitem(text) {
    return `<li class="markdown-li">${text}</li>`;
  },
  blockquote(quote) {
    return `<blockquote class="markdown-blockquote">${quote}</blockquote>`;
  },
  code(code, language) {
    return `<pre class="markdown-pre"><code class="markdown-code ${language ? `language-${language}` : ""}">${code}</code></pre>`;
  },
  hr() {
    return '<hr class="markdown-hr">';
  },
  image(href, title, text) {
    return `<img src="${href}" alt="${text}" title="${title || ""}" class="markdown-img">`;
  },
  strong(text) {
    return `<strong class="markdown-strong">${text}</strong>`;
  },
  em(text) {
    return `<em class="markdown-em">${text}</em>`;
  },
  codespan(code) {
    return `<code class="markdown-codespan">${code}</code>`;
  },
  link(href, title, text) {
    return `<a href="${href}" title="${title || text}" target="_blank" class="markdown-link">${text}</a>`;
  },
  table(header, body) {
    const count = (header.match(/class="[^"]*th[^"]*"[^>]*>/gi) || []).length;
    const style = `"grid-template-columns: repeat(${count}, 1fr)"`;
    return `<div class="table-wrapper grid markdown-table" style=${style}>${header}${body}</div>`;
  },
  tablerow(content) {
    const nodes = createVirtualDOM(content)?.children ?? [];
    const hasEmptyCell = nodes.some((node) => !node.children?.length);
    const singleSkeleton = `<div class="h-12 animate-pulse bg-gray-200 td m-2"></div>`;

    return hasEmptyCell
      ? `<div class="contents markdown-tr">
          ${nodes.map(() => singleSkeleton).join("")}
         </div>`
      : `<div class="contents markdown-tr">${content}</div>`;
  },
  tablecell(content, flags) {
    const tag = flags.header ? "th" : "td";
    return `<div class="${tag} markdown-${tag}">${content}</div>`;
  },
};

marked.use({ renderer });
