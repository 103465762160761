import logger from "@/services/logger";
import { createAuth0 } from "@auth0/auth0-vue";
import axios from "axios";

export const clientOptions = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    scope: "openid profile email offline_access",
  },
  cacheLocation: "localstorage",
  useRefreshTokens: true,
};

export const auth0 = createAuth0(clientOptions);

export const getAccessToken = async () => {
  return await auth0.getAccessTokenSilently({
    cacheMode: "on",
    authorizationParams: clientOptions.authorizationParams,
  });
};

export const fetchWithToken = async (url, options = {}) => {
  const accessToken = await getAccessToken();

  const headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios({
      url,
      method: options.method || "GET",
      headers,
      data: options.data || null,
      params: options.params || null,
    });
    return response.data;
  } catch (error) {
    logger.error("Error in fetchWithToken:", error);
    throw error;
  }
};
