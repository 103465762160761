<template>
  <div class="w-full">
    <div v-if="!compareView" class="mb-2 flex w-full items-center p-2">
      <h2 class="font-bold">
        {{ title }}
        <span v-if="mappedProducts?.length" class="font-normal">
          ({{ mappedProducts.length }} {{ resultsText() }})
        </span>
      </h2>
    </div>
    <div v-if="compareView" class="flex w-full items-center p-2">
      <img src="@/assets/icons/magic.svg" alt="magic-icon" />
      <h2 class="ml-4 text-xs font-medium uppercase text-grey-700">
        {{ $t("compare.title") }}
      </h2>
      <div
        class="ml-4 rounded-md bg-grey-700 px-3 py-2 text-xs font-normal text-white"
      >
        {{ $t("compare.select_up_to_three") }}
      </div>
    </div>

    <div
      class="olx-horizontal-scrollbar flex w-full gap-4 overflow-x-auto pb-2"
    >
      <!-- Added padding-bottom for scrollbar visibility -->
      <RouterLink
        v-for="(product, index) in mappedProducts"
        :key="product.id"
        class="w-40 shrink-0 cursor-pointer overflow-hidden bg-white"
        :to="`/buy/${product.id}?item_position=${index + 1}`"
      >
        <div class="relative">
          <Carousel :images="product.photos" />
          <div class="absolute top-2 flex w-full justify-between px-1">
            <div
              v-if="product.params.state"
              class="h-5 rounded bg-grey-300 px-2 py-1 text-xs font-normal uppercase text-grey-700"
            >
              {{ product.params.state }}
            </div>
            <Dropdown
              v-if="!index && isLastProductsMessage"
              class="inline"
              popper-class="custom-dropdown w-80 md:w-96"
              :shown="onboardingTipVisible.select_smart_compare"
              :auto-hide="true"
              :distance="10"
              placement="bottom"
              append-to="parent"
            >
              <template #popper="{ hide }">
                <DropdownPopper
                  :hide="hide"
                  :tooltip-text="'tour_tooltip_text.select_smart_compare'"
                />
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="flex flex-col gap-1 p-2">
          <div class="flex items-center gap-2">
            <h2 class="truncate text-sm font-normal" :title="product.title">
              {{ product.title }}
            </h2>
            <span
              class="cursor-pointer"
              @click.stop.prevent="toggleFavourite(product, $event)"
            >
              <img
                :src="
                  isFavourite(product)
                    ? require('@/assets/icons/favourite_active.svg')
                    : require('@/assets/icons/favourite-black.svg')
                "
                alt="favourite-black-icon"
                class="size-4 max-w-none"
              />
            </span>
          </div>
          <div v-if="product.short_info" class="flex h-8 items-center gap-2">
            <img src="@/assets/icons/right.svg" alt="compare" />
            <h2
              class="line-clamp-2 text-xs font-normal text-[#179992]"
              :title="product.short_info"
            >
              {{ product.short_info }}
            </h2>
          </div>
          <div v-if="product.params" class="flex items-center justify-between">
            <p class="text-base font-medium leading-5 text-red-500">
              {{ product.params.price }} zł
            </p>
            <span
              v-if="!compareView"
              :class="
                $twMerge(
                  'w-1/2 shrink-0 cursor-pointer text-right',
                  'text-xs font-normal text-grey-700',
                  'underline decoration-1 underline-offset-2',
                )
              "
              @click.stop.prevent="handleFindSimilar(product)"
            >
              {{ $t("product.find_similar") }}
            </span>

            <BaseCheckbox
              v-if="compareView"
              :id="`select-product-${product.id}`"
              :checked="product.isSelected"
              @change="handleSelection(product)"
            />
          </div>
        </div>
      </RouterLink>
    </div>
    <SmartCompare @compare-view="handleCompareView" />
    <div v-if="!compareView" class="mt-2 flex items-center justify-end">
      <span class="mr-2 text-xs text-[#11726D]">
        {{ $t("product.actions_title") }}
      </span>
      <img src="@/assets/icons/magic.svg" alt="magic-icon" />
    </div>
  </div>
</template>

<script>
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
import {
  MAGIC_ADD_FAVOURITE,
  MAGIC_FIND_SIMILAR,
  MAGIC_REMOVE_FAVOURITE,
  MAGIC_SEARCH_RESULTS,
} from "@/constants/eventsConstants";
import { Dropdown } from "floating-vue";
import { RouterLink } from "vue-router";
import { mapActions, mapGetters, mapState } from "vuex";
import TrackingService from "../../../services/TrackingService";
import DropdownPopper from "../../navbar/DropdownPopper.vue";
import Carousel from "./Carousel.vue";
import SmartCompare from "./SmartCompare.vue";

export default {
  name: "Products",
  components: {
    Carousel,
    RouterLink,
    Dropdown,
    DropdownPopper,
    SmartCompare,
    BaseCheckbox,
  },
  props: {
    title: {
      type: String,
      default: "Products",
    },
    products: {
      type: Array,
      default: () => [],
    },
    messageTimestamp: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
      modalDescription: "",
      selectedIcon: require("@/assets/icons/selected_checkbox.svg"),
      defaultIcon: require("@/assets/icons/default_checkbox.svg"),
      MAGIC_FIND_SIMILAR,
      compareView: false,
    };
  },
  computed: {
    ...mapGetters("favourites", ["favourites"]),
    ...mapGetters("selected", ["selected"]),
    ...mapState("messages", ["messages", "task_status"]),
    ...mapGetters("floatingItems", ["onboardingTipVisible"]),
    mappedProducts() {
      return this.products?.map((product) => ({
        ...product,
        isSelected: this.isSelected(product),
        isFavourite: this.isFavourite(product),
      }));
    },
    isDisabled() {
      return this.selected.length < 2;
    },
    isLastProductsMessage() {
      const msgs = this.messages.filter((msg) => !!msg.products?.length);
      const index = msgs.findIndex(
        (msg) =>
          msg.timestamp?.toString() === this.messageTimestamp?.toString(),
      );
      return index === msgs.length - 1;
    },
  },
  mounted() {
    const productIds = this.mappedProducts.map((product) => product.id);

    TrackingService.trackPage({
      ...MAGIC_SEARCH_RESULTS,
      ad_impressions: productIds,
    });
  },
  methods: {
    ...mapActions("favourites", ["addFavourite", "removeFavourite"]),
    ...mapActions("selected", ["addSelected", "removeSelected"]),
    ...mapActions("messages", ["findSimilar"]),
    toggleFavourite(item) {
      if (this.isFavourite(item)) {
        this.$tracking.trackEvent("click", MAGIC_REMOVE_FAVOURITE);
        this.removeFavourite(item.url);
      } else {
        this.$tracking.trackEvent("click", MAGIC_ADD_FAVOURITE);
        this.addFavourite(item);
      }
    },
    handleSelection(product) {
      if ((this.selected?.length ?? 0) < 3 || product.isSelected) {
        this.toggleSelected(product);
      }
    },
    toggleSelected(product) {
      if (product.isSelected) {
        this.removeSelected(product.url);
      } else {
        this.addSelected(product);
      }
    },
    isFavourite(item) {
      return this.favourites?.some((favourite) => favourite.url === item.url);
    },
    isSelected(item) {
      return this.selected?.some((selected) => selected.url === item.url);
    },
    handleFindSimilar(item) {
      this.$tracking.trackEvent("click", MAGIC_FIND_SIMILAR);
      this.findSimilar(item);
    },
    resultsText() {
      const length = this.mappedProducts.length;
      if (length === 1) {
        return this.$t("compare.results.one");
      } else if (length >= 2 && length <= 4) {
        return this.$t("compare.results.few");
      } else {
        return this.$t("compare.results.many");
      }
    },
    handleCompareView(newCompareView) {
      this.compareView = newCompareView;
    },
  },
};
</script>
