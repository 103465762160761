<template>
  <div class="flex h-14 items-center justify-between">
    <button @click="$emit('close')">
      <img src="@/assets/icons/close.svg" alt="close-icon" class="size-5" />
    </button>
    <div class="flex h-4 items-center gap-1">
      <img
        src="@/assets/icons/stroke.svg"
        alt="favourite-black-icon"
        class="size-4"
      />
      <h1 class="pt-0.5 text-base font-medium uppercase text-teal-800">
        {{ title }}
      </h1>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "ModalHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["close"],
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
