<template>
  <div class="flex h-4 items-center justify-between pb-3 pl-4 pr-2 pt-5">
    <h1 class="text-base font-medium uppercase tracking-wider text-magic-800">
      {{ $t("magic_search_two_point.search_page.magic_results") }}
      ({{ products?.length }})
    </h1>
    <div ref="dropdown" class="relative" @click="toggleDropdown">
      <button class="p-1">
        <SortingIcon class="mr-1 size-5 text-magic-800" />
      </button>
      <div
        v-if="isOpen"
        :class="[
          'absolute right-0 top-6 shadow-lg',
          'z-10 w-52 rounded border border-gray-300 bg-white p-1',
        ]"
      >
        <ul>
          <li
            v-for="item in sortOptions"
            :key="item.value"
            :class="[
              'cursor-pointer rounded px-3 py-1 text-sm',
              sortMethod === item.value ? 'bg-gray-100' : 'bg-white',
            ]"
            @click="selectOption(item.value)"
          >
            {{ $t(item.label) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SortingIcon from "../../ui/icons/SortingIcon.vue";

export default {
  name: "ProductsHeader",
  components: {
    SortingIcon,
  },
  data() {
    return {
      isOpen: false,
      sortOptions: [
        {
          value: "relevance",
          label: "magic_search_two_point.filters.relevance",
        },
        {
          value: "price_low",
          label: "magic_search_two_point.filters.price_low",
        },
        {
          value: "price_high",
          label: "magic_search_two_point.filters.price_high",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("search", ["products", "sortMethod"]),
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapActions("search", ["setSortMethod"]),
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.setSortMethod(option);
      this.isOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style>
/* css */
</style>
