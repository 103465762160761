<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="currentColor"
  >
    <g clip-path="url(#clip0_695_343520)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.66602 2.66602C3.38987 2.66602 3.16602 2.88987 3.16602 3.16602V4.92906V4.92911C3.16607 5.44723 3.35919 5.94674 3.70768 6.33015L3.70771 6.33019L7.12435 10.0885V16.6243C7.12435 16.7851 7.2016 16.936 7.33198 17.03C7.46235 17.1239 7.62996 17.1495 7.78243 17.0987L10.942 16.0458C11.2039 15.9585 11.3455 15.6753 11.2582 15.4134C11.1709 15.1514 10.8878 15.0098 10.6258 15.0971L8.12435 15.9307V9.89518C8.12435 9.7708 8.07799 9.65088 7.99432 9.55885L4.44768 5.65755L4.44765 5.65751C4.26647 5.45816 4.16606 5.19845 4.16602 4.92906V4.929V3.66602H15.8327V4.88541V4.88552C15.8326 5.17277 15.7184 5.44823 15.5153 5.65133L15.5152 5.65138L12.0208 9.1458C11.927 9.23956 11.8743 9.36674 11.8743 9.49935V11.8743C11.8743 12.1505 12.0982 12.3743 12.3743 12.3743C12.6505 12.3743 12.8743 12.1505 12.8743 11.8743V9.70646L16.2223 6.35854L16.2223 6.35849C16.613 5.96788 16.8326 5.43809 16.8327 4.88562V4.88552V3.16602C16.8327 2.88987 16.6088 2.66602 16.3327 2.66602H3.66602ZM13.363 13.2612C13.8142 12.8093 14.0365 12.2812 14.0365 11.6582H14.5906C14.5906 12.2812 14.813 12.8093 15.2642 13.2612C15.7143 13.712 16.2555 13.9452 16.9084 13.9797L16.9065 14.5077C16.4805 14.5274 16.0954 14.6396 15.7398 14.8537L15.7389 14.8543C15.3878 15.0639 15.1073 15.3463 14.8962 15.701C14.6923 16.053 14.5906 16.4277 14.5906 16.84H14.0365C14.0365 16.2164 13.8137 15.683 13.363 15.2316L13.362 15.2306C12.9108 14.7738 12.3702 14.5371 11.7266 14.5077V13.9796C12.3704 13.9502 12.9115 13.7134 13.363 13.2612Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_695_343520">
        <rect width="19" height="19" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
