<template>
  <p>...</p>
</template>

<script>
import logger from "@/services/logger";

export default {
  name: "LogoutPage",
  mounted() {
    this.$nextTick(() => {
      try {
        this.$auth0.logout();
      } catch (error) {
        logger.error(error);
        this.$router.push("/");
      }
    });
  },
};
</script>
