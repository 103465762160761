<template>
  <div
    v-if="isOpen"
    :class="
      $twMerge(
        'fixed inset-0 z-50 flex items-center justify-center',
        'bg-black/50 backdrop-blur-sm',
      )
    "
  >
    <div
      :class="
        $twMerge(
          'top-position-modal relative mx-4 flex w-full max-w-4xl flex-col gap-2',
          'rounded-lg bg-grey-300 p-6 shadow-lg sm:mx-auto',
        )
      "
    >
      <div class="flex justify-start">
        <button
          :class="
            $twMerge(
              'rounded-lg p-1 font-bold text-gray-700',
              'hover:bg-gray-300',
            )
          "
          @click="goBack"
        >
          <img src="@/assets/icons/back.svg" class="size-6" alt="Back Button" />
        </button>
      </div>
      <div class="flex flex-col gap-2">
        <div
          :class="
            $twMerge(
              'relative flex max-h-96 min-h-48 justify-center',
              'rounded-lg border border-solid border-grey-400',
            )
          "
        >
          <img
            :src="imageSrc"
            alt="Preview"
            :class="
              $twMerge('size-auto object-contain px-6', {
                'blur-sm':
                  imageSrc && (isUploadDisabled || task_status !== 'completed'),
              })
            "
          />
          <Loader
            v-if="imageSrc && (isUploadDisabled || task_status !== 'completed')"
            :class="
              $twMerge('absolute inset-0 flex items-center justify-center')
            "
          />
        </div>
        <div
          :class="
            $twMerge(
              'flex h-12 items-center justify-between gap-2',
              'rounded-lg border border-solid border-grey-400',
              'bg-white pl-4 pr-2 shadow-inputBox',
            )
          "
        >
          <textarea
            v-model="inputText"
            :placeholder="$t('attachment.input_placeholder')"
            :class="
              $twMerge(
                'h-5 w-full resize-none border-none p-0 pl-2',
                'text-sm font-normal leading-snug',
                'focus:border-none focus:outline-none focus:ring-0',
              )
            "
            @keydown="onKeyDown"
          ></textarea>
        </div>
      </div>
      <div
        :class="$twMerge('flex flex-col-reverse justify-end gap-2 sm:flex-row')"
      >
        <button
          v-track:click="MAGIC_ATTACHMENT_CANCEL"
          :class="
            $twMerge(
              'rounded border-2 border-solid border-red-500',
              'px-6 py-2 text-sm font-medium text-red-500',
            )
          "
          @click="closeModal"
        >
          {{ $t("attachment.cancel") }}
        </button>
        <button
          v-track:click="MAGIC_ATTACHMENT_UPLOAD"
          :class="[
            'rounded border-2 border-solid border-grey-700',
            'bg-grey-700 px-6 py-2 text-sm font-medium text-white',
            {
              'cursor-not-allowed opacity-30':
                isUploadDisabled || task_status !== 'completed',
              'hover:cursor-pointer hover:border-grey-600 hover:bg-grey-600':
                !isUploadDisabled,
            },
          ]"
          :disabled="isUploadDisabled"
          @click="upload"
        >
          {{ $t("attachment.upload") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/ui/Loader.vue";
import {
  MAGIC_ATTACHMENT_CANCEL,
  MAGIC_ATTACHMENT_UPLOAD,
} from "@/constants/eventsConstants";
import { mapState } from "vuex";
export default {
  components: {
    Loader,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
    filePreviews: {
      type: Array,
      default: () => [],
    },
    filesUrls: {
      type: Array,
      default: () => [],
    },
    messageText: {
      type: String,
      default: "",
    },
    isUploadDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:files-urls", "close", "back", "uploadClick"],
  data() {
    return {
      inputText: this.messageText || "",
      MAGIC_ATTACHMENT_CANCEL,
      MAGIC_ATTACHMENT_UPLOAD,
    };
  },
  computed: {
    ...mapState("messages", ["task_status"]),
  },
  watch: {
    messageText(newVal) {
      this.inputText = newVal;
    },
    isOpen(newVal) {
      if (newVal) this.$emit("update:files-urls");
    },
    isUploadDisabled() {},
  },
  methods: {
    closeModal() {
      this.$emit("update:files-urls");
      this.$emit("close");
    },
    async upload(event) {
      this.sendUserMessage(event);
      this.closeModal();
    },
    goBack() {
      this.$emit("update:files-urls");
      this.$emit("back");
    },
    onKeyDown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          const input = event.target;
          const start = input.selectionStart;
          const end = input.selectionEnd;
          this.inputText =
            this.inputText.substring(0, start) +
            "\n" +
            this.inputText.substring(end);
          input.value = this.inputText;
          this.$nextTick(() => {
            input.setSelectionRange(start + 1, start + 1);
          });
        } else {
          event.preventDefault();
          if (this.isUploadDisabled) return;
          this.sendUserMessage(event);
        }
      }
    },
    async sendUserMessage(event) {
      event.preventDefault();
      if (this.filesUrls.length > 0 && this.task_status === "completed") {
        const messageText =
          this.inputText.trim() === ""
            ? this.$t("search.image_placeholder_text")
            : this.inputText;
        const message = {
          text: messageText,
          filesUrls: this.filesUrls,
          isUser: true,
        };

        this.inputText = "";
        this.$emit("close");
        this.$emit("update:files-urls");
        this.$emit("uploadClick", message);
      }
    },
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
