const state = {
  selected: new Set(),
  allItems: {},
};

const getters = {
  selected: (state) =>
    Array.from(state.selected).map((url) => state.allItems[url] || {}),
};

const actions = {
  addSelected({ commit }, item) {
    commit("ADD_SELECTED", item);
  },
  removeSelected({ commit }, url) {
    commit("REMOVE_SELECTED", url);
  },
  clearSelected({ commit }) {
    commit("CLEAR_SELECTED");
  },
  compareSelected({ commit, state, dispatch }) {
    dispatch(
      "messages/sendStreamingTask",
      {
        value: getters.selected(state),
        messageType: "smart_compare",
        isUser: true,
      },
      { root: true },
    );
    commit("CLEAR_SELECTED");
    commit("messages/CLEAR_FOLLOW_UP_RESPONSES", [], { root: true });
  },
};

const mutations = {
  ADD_SELECTED(state, item) {
    state.selected.add(item.url);
    state.allItems[item.url] = item;
  },
  REMOVE_SELECTED(state, url) {
    state.selected.delete(url);
    delete state.allItems[url];
  },
  CLEAR_SELECTED(state) {
    state.selected.clear();
    state.allItems = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
