import router from "@/router";
import logger from "@/services/logger";

export const MESSAGE_SEPARATOR = "\n<------>\n";

/**
 * Moves to new route with new params
 */
export function moveToNewSearch({ newQuery, newTags, newImage, ...rest } = {}) {
  const query = {};
  const tags = newTags?.join(";");

  if (newQuery) query.q = newQuery;
  if (tags) query.tags = tags;
  if (newImage) query.image = btoa(newImage);

  Object.entries(rest).forEach(([key, value]) => {
    query[key] = value;
  });

  router.push({ path: "/search", query });
}

export function createCustomList(
  products,
  groupsLoading,
  productGroups = [],
  queryGroups = [],
) {
  const GROUP_EVERY_X_PRODUCTS = 3;
  const SKELETON_COUNT_FOR_PRODUCT_GROUPS = 4;

  const result = [];
  let productIndex = 0,
    pGroupIndex = 0,
    qGroupIndex = 0,
    sGroupIndex = 0;
  const pGroupsExhausted = () => pGroupIndex >= (productGroups?.length ?? 0);
  const qGroupsExhausted = () => qGroupIndex >= (queryGroups?.length ?? 0);
  const sGroupsExhausted = () =>
    sGroupIndex >= SKELETON_COUNT_FOR_PRODUCT_GROUPS;

  while (
    productIndex < products.length ||
    !pGroupsExhausted() ||
    !qGroupsExhausted()
  ) {
    // Add products till next group
    for (
      let i = 0;
      i < GROUP_EVERY_X_PRODUCTS && productIndex < products.length;
      i++
    ) {
      result.push({ ...products[productIndex], isGroup: false });
      productIndex++;
    }

    if (groupsLoading && !sGroupsExhausted()) {
      result.push({
        id: `skeleton-group-${sGroupIndex}`,
        isGroup: true,
        isLoader: true,
        isQueryGroup: false,
      });
      sGroupIndex++;
    } else if (!pGroupsExhausted()) {
      const group = productGroups[pGroupIndex];
      result.push({
        ...group,
        id: group.title,
        isGroup: true,
        isQueryGroup: false,
        items: group.item_ids
          .map((id) => products.find((p) => p.id === id))
          .filter((p) => !!p),
      });
      pGroupIndex++;
    } else if (!qGroupsExhausted()) {
      const group = queryGroups[qGroupIndex];
      result.push({
        ...group,
        id: group.title,
        isGroup: true,
        isQueryGroup: true,
      });
      qGroupIndex++;
    }
  }

  return result
    .filter((v) => !!v)
    .filter((item) => {
      if (item.isGroup && !item.loading && item.items?.length === 0) {
        logger.warn("Group without items:", item);
        return false;
      }
      return true;
    });
}
