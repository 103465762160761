<template>
  <img
    src="@/assets/icons/olx-magic-app.svg"
    alt="OLX Magic App"
    class="mb-4 w-full rounded-md"
  />
  <div class="flex items-end justify-between">
    <div class="flex-col">
      <p class="mb-1 font-medium text-grey-700">
        {{ $t("app_download.available") }}
      </p>
      <p class="mb-2 text-sm text-grey-700">
        {{ $t("app_download.info") }}
      </p>
      <div class="mt-4 flex">
        <a :href="playStoreLink" target="_blank" class="mr-2">
          <img
            src="@/assets/icons/google-play-badge.svg"
            alt="Google Play"
            class="h-10"
          />
        </a>
        <a :href="appStoreLink" target="_blank">
          <img
            src="@/assets/icons/app-store-badge.svg"
            alt="App Store"
            class="h-10"
          />
        </a>
      </div>
    </div>
    <img class="hidden max-w-36 md:block" :src="qrLink" alt="QR Code" />
  </div>
</template>

<script>
import {
  VUE_APP_APP_STORE_LINK,
  VUE_APP_PLAY_STORE_LINK,
} from "@/constants/storeLinks";
import { isSearchRoute } from "../product-page/utils";

export default {
  name: "DownloadAppLinks",
  data() {
    return {
      playStoreLink: VUE_APP_PLAY_STORE_LINK,
      appStoreLink: VUE_APP_APP_STORE_LINK,
      qrLink: `/img/download_qr/download_qr_${process.env.VUE_APP_STAGE ?? "production"}.png`,
    };
  },
  mounted() {
    this.$tracking.trackEvent(
      "click",
      isSearchRoute()
        ? this.$EVENTS?.HYBRID?.MAGIC_DOWNLOAD_APP_VISIBLE
        : this.$EVENTS?.CORE?.MAGIC_DOWNLOAD_APP_VISIBLE,
    );
  },
};
</script>
