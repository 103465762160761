<template>
  <div
    v-if="showCameraModal"
    :class="
      $twMerge(
        'fixed inset-0 z-50 flex items-center justify-center',
        'bg-black/50 backdrop-blur-sm',
      )
    "
  >
    <div
      :class="
        $twMerge(
          'mx-4 flex h-4/5 w-full max-w-4xl flex-col gap-2',
          'rounded-lg bg-grey-300 p-4 shadow-lg sm:mx-auto sm:gap-4',
        )
      "
    >
      <div :class="$twMerge('flex items-center justify-end')">
        <button
          v-track:click="MAGIC_CAMERA_CLOSE"
          :class="
            $twMerge(
              'flex size-7 items-center justify-center rounded-lg',
              'text-2xl font-medium text-grey-700 hover:bg-gray-300',
              'focus:outline-none sm:size-10',
              {
                'cursor-not-allowed': capturedPhoto && filesUrls.length === 0,
              },
            )
          "
          :disabled="capturedPhoto && filesUrls.length === 0"
          @click="closeCameraModal"
        >
          <img class="size-6" src="@/assets/icons/close.svg" alt="close-icon" />
        </button>
      </div>
      <div
        :class="
          $twMerge(
            'relative flex h-[calc(100%-9rem)] w-full items-center justify-center',
            'bg-gray-200 sm:h-[calc(100%-11rem)]',
          )
        "
      >
        <div
          v-if="showPlaceholder"
          :class="
            $twMerge(
              'relative flex size-full flex-col items-center justify-center',
              'bg-gray-800 text-center text-white',
            )
          "
        >
          <div
            :class="
              $twMerge(
                'absolute left-10p top-10p size-12 rounded-tl-xl',
                'border-l-4 border-t-4 border-white sm:rounded-tl-3xl',
              )
            "
          ></div>
          <div
            :class="
              $twMerge(
                'absolute right-10p top-10p size-12 rounded-tr-xl',
                'border-r-4 border-t-4 border-white sm:rounded-tr-3xl',
              )
            "
          ></div>
          <div
            :class="
              $twMerge(
                'absolute bottom-10p left-10p size-12 rounded-bl-xl',
                'border-b-4 border-l-4 border-white sm:rounded-bl-3xl',
              )
            "
          ></div>
          <div
            :class="
              $twMerge(
                'absolute bottom-10p right-10p size-12 rounded-br-xl',
                'border-b-4 border-r-4 border-white sm:rounded-br-3xl',
              )
            "
          ></div>
          <p :class="$twMerge('placeholder:text-text mt-20 text-xl font-bold')">
            {{ $t("camera.heading") }}
          </p>
          <p :class="$twMerge('placeholder:text-subtext p-4 text-base')">
            {{ $t("camera.sub_heading") }}
          </p>
        </div>
        <video
          v-if="!capturedPhoto"
          ref="video"
          class="size-full"
          :class="{ hidden: showPlaceholder }"
        ></video>
        <div v-else :class="$twMerge('relative size-full')">
          <img
            :src="capturedPhoto"
            alt="Image Preview"
            :class="
              $twMerge('size-full object-contain', {
                'blur-sm':
                  (capturedPhoto && filesUrls.length === 0) ||
                  task_status !== 'completed',
              })
            "
          />
          <Loader
            v-if="
              (capturedPhoto && filesUrls.length === 0) ||
              task_status !== 'completed'
            "
            :class="
              $twMerge('absolute inset-0 flex items-center justify-center')
            "
          />
        </div>
      </div>
      <div
        v-if="capturedPhoto"
        :class="
          $twMerge(
            'flex h-12 items-center justify-between gap-2',
            'rounded-lg border border-solid border-grey-400',
            'bg-white pl-4 pr-2 shadow-inputBox',
          )
        "
      >
        <textarea
          v-model="inputText"
          :placeholder="$t('camera.input_placeholder')"
          :class="
            $twMerge(
              'h-5 w-full resize-none border-none p-0 pl-2',
              'text-sm font-normal leading-snug',
              'focus:border-none focus:outline-none focus:ring-0',
            )
          "
          @keydown="onKeyDown"
        ></textarea>
      </div>
      <div :class="$twMerge('flex justify-end gap-4')">
        <div
          :class="
            $twMerge(
              'flex w-full flex-col-reverse justify-end gap-2',
              'transition-all sm:flex-row',
            )
          "
        >
          <button
            v-if="capturedPhoto"
            :class="
              $twMerge(
                'rounded border-2 border-solid border-red-500',
                'px-6 py-2 text-sm font-medium text-red-500',
              )
            "
            @click="onRetake"
          >
            {{ $t("camera.retake_btn") }}
          </button>
          <button
            v-track:click="capturedPhoto ? '' : MAGIC_CAMERA_TAKE_PHOTO"
            :class="
              $twMerge(
                'rounded border-2 border-solid border-grey-700',
                'bg-grey-700 px-6 py-2 text-sm font-medium text-white',
                'hover:cursor-pointer hover:border-grey-600 hover:bg-grey-600',
                {
                  'cursor-not-allowed opacity-30':
                    (capturedPhoto && filesUrls.length === 0) ||
                    task_status !== 'completed',
                },
              )
            "
            @click="handleUploadClick"
          >
            {{
              capturedPhoto ? $t("camera.upload_btn") : $t("camera.click_btn")
            }}
          </button>
        </div>
      </div>
      <div
        v-if="showCaptureAnimation"
        :class="
          $twMerge(
            'capture-animation absolute inset-0 flex items-center justify-center',
          )
        "
      >
        <div
          :class="$twMerge('capture-flash size-full bg-white opacity-75')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MAGIC_CAMERA_CLOSE,
  MAGIC_CAMERA_TAKE_PHOTO,
} from "@/constants/eventsConstants";
import { mapActions, mapState } from "vuex";
import Loader from "@/components/ui/Loader.vue";
import logger from "@/services/logger";

export default {
  name: "CameraModal",
  components: {
    Loader,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    filePreviews: {
      type: Array,
      default: () => [],
    },
    filesUrls: {
      type: Array,
      default: () => [],
    },
    messageText: {
      type: String,
      default: "",
    },
  },
  emits: ["photo-captured", "update:files-urls"],
  data() {
    return {
      showCameraModal: false,
      showCaptureAnimation: false,
      capturedPhoto: null,
      showPlaceholder: true,
      inputText: this.messageText || "",
      MAGIC_CAMERA_TAKE_PHOTO,
      MAGIC_CAMERA_CLOSE,
    };
  },
  computed: {
    ...mapState("messages", ["task_status"]),
  },
  watch: {
    messageText(newVal) {
      this.inputText = newVal;
    },
    showCameraModal(newVal) {
      if (newVal) this.$emit("update:files-urls");
    },
  },
  beforeUnmount() {
    this.stopCamera();
  },
  methods: {
    ...mapActions("messages", ["sendMessage"]),
    handleUploadClick(event) {
      if (this.capturedPhoto) {
        this.sendUserMessage(event);
      } else {
        this.takePhoto();
      }
    },
    openCameraModal() {
      this.showCameraModal = true;
      this.showPlaceholder = true;
      this.startCamera();
      setTimeout(() => {
        this.showPlaceholder = false;
      }, 800);
    },
    closeCameraModal() {
      this.capturedPhoto = null;
      this.inputText = "";
      this.$emit("update:files-urls");
      this.showCameraModal = false;
      this.stopCamera();
    },
    startCamera() {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.$refs.video.srcObject = stream;
          this.$refs.video.play();
        })
        .catch((error) => {
          logger.error("Error accessing camera:", error);
        });
    },
    stopCamera() {
      const stream = this.$refs.video?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        this.$refs.video.srcObject = null;
      }
    },
    takePhoto() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0);
      canvas.toBlob(async (blob) => {
        const photo_id = Math.random().toString(36).substring(7);
        const file = new File([blob], `${photo_id}.webp`, {
          type: "image/webp",
        });
        this.$emit("photo-captured", file);
        this.capturedPhoto = URL.createObjectURL(blob);
        this.showCaptureAnimation = true;
        setTimeout(() => {
          this.showCaptureAnimation = false;
        }, 500);
        this.stopCamera();
      }, "image/webp");
    },
    onRetake() {
      this.capturedPhoto = null;
      this.showPlaceholder = true;
      this.$emit("update:files-urls");
      this.startCamera();
      setTimeout(() => {
        this.showPlaceholder = false;
      }, 800);
    },
    onKeyDown(event) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          const input = event.target;
          const start = input.selectionStart;
          const end = input.selectionEnd;
          this.inputText =
            this.inputText.substring(0, start) +
            "\n" +
            this.inputText.substring(end);
          input.value = this.inputText;
          this.$nextTick(() => {
            input.setSelectionRange(start + 1, start + 1);
          });
        } else {
          event.preventDefault();
          this.sendUserMessage(event);
        }
      }
    },
    async sendUserMessage(event) {
      event.preventDefault();
      if (this.filesUrls.length > 0 && this.task_status === "completed") {
        const messageText =
          this.inputText.trim() === ""
            ? this.$t("search.image_placeholder_text")
            : this.inputText;
        const message = {
          text: messageText,
          filesUrls: this.filesUrls,
          isUser: true,
        };
        await this.sendMessage(message);
        this.inputText = "";
        this.$emit("update:files-urls");
        this.closeCameraModal();
      }
    },
  },
};
</script>

<style scoped>
.capture-animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  animation: flash 0.5s ease-out;
}

@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
