<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center">
    <div class="absolute inset-0 bg-black/50" @click="$emit('close')"></div>
    <div class="z-10 mx-4 rounded-lg bg-white p-6 shadow-lg sm:mx-0">
      <slot></slot>
      <button
        v-if="showCloseButton"
        class="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
        @click="$emit('close')"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close"],
};
</script>
