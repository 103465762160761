export const TAG_SEPARATOR = ";";

export function mapSearchPageRouteQueryParams(params) {
  const { q, tags, image, product_id, skip_expansion, ...rest } = params;

  return {
    queryText: q,
    queryTags: tags?.split(TAG_SEPARATOR).map((v) => v.trim()) || [],
    queryImage: image ? atob(image) : undefined,
    queryProductId: product_id,
    skipExpansion: skip_expansion === "true",
    ...rest,
  };
}

export function isContextualEntry(queryParams) {
  return Boolean(
    queryParams.query ||
      queryParams.exp ||
      queryParams.st ||
      queryParams.rawData,
  );
}
