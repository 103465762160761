<template>
  <div
    v-if="selectedAdIds.length > 0"
    variant="magic_primary"
    class="flex h-9 w-max items-center gap-2 !rounded-full !bg-grey-300 px-3 py-1.5 text-grey-700 shadow-custom"
  >
    <div class="flex justify-end gap-1 text-sm font-medium">
      <span class="flex w-6 justify-end pt-1">
        {{ selectedAdIds.length }}/3
      </span>
      <span class="pt-1">
        {{ $t("magic_search_two_point.pop_up_text") }}
      </span>
    </div>
    <BaseButton variant="icon" @click="CLEAR_SELECTED_AD_IDS">
      <Icon icon="mdi:close" class="text-sm" />
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { Icon } from "@iconify/vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SelectedAdCounter",
  components: { Icon, BaseButton },
  computed: {
    ...mapGetters("hybridActions", ["selectedAdIds"]),
  },
  methods: {
    ...mapMutations("hybridActions", ["CLEAR_SELECTED_AD_IDS"]),
  },
};
</script>
