const state = {
  favourites: new Set(),
  allItems: {},
};

const getters = {
  favourites: (state) =>
    Array.from(state.favourites).map((url) => state.allItems[url] || {}),
};

const actions = {
  addFavourite({ commit }, item) {
    commit("ADD_FAVOURITE", item);
  },
  removeFavourite({ commit }, url) {
    commit("REMOVE_FAVOURITE", url);
  },
  clearFavourites({ commit }) {
    commit("CLEAR_FAVOURITES");
  },
};

const mutations = {
  ADD_FAVOURITE(state, item) {
    state.favourites.add(item.url);
    state.allItems[item.url] = item;
  },
  REMOVE_FAVOURITE(state, url) {
    state.favourites.delete(url);
    delete state.allItems[url];
  },
  CLEAR_FAVOURITES(state) {
    state.favourites.clear();
    state.allItems = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
