<template>
  <div
    v-scroll-to-element
    class="container mx-auto flex scroll-mt-32 flex-col items-stretch p-2 md:gap-4 md:p-0 lg:flex-row"
  >
    <div class="h-auto w-full overflow-auto">
      <div
        class="z-10 flex h-12 flex-row items-center gap-4 md:sticky md:top-0 md:h-16 md:bg-gray-100"
      >
        <RouterLink to="/buy">
          <img
            src="@/assets/icons/left_arrow.svg"
            class="mb-1 mr-3 inline size-5"
            alt="Left Arrow Icon"
          />
          {{ $t("product.breadcrumb_conversation") }}
        </RouterLink>
      </div>

      <div class="h-auto w-full rounded-md bg-white pb-2">
        <div class="header sticky top-0 z-10 w-full rounded-md bg-white p-4">
          <h3 class="py-4 text-xl font-bold text-gray-800">
            {{ $t("product_drawer.favorite") }}
          </h3>
        </div>
        <div
          v-if="favourites && favourites.length > 0"
          class="m-4 flex gap-2 overflow-y-auto rounded-md bg-grey-300 p-2.5"
        >
          <div
            v-for="item in favourites"
            :key="item.url"
            class="relative w-40 cursor-pointer bg-white"
            @click="openUrlInNewTab(item.url)"
          >
            <BaseImage
              :src="item.photos[0]"
              class="h-40 w-full object-cover"
              :alt="item.title"
            />
            <div class="flex flex-col gap-1 p-4">
              <div class="flex items-center gap-2">
                <h2 class="truncate text-sm font-normal">
                  {{ item.title }}
                </h2>
                <span
                  v-track:click="MAGIC_REMOVE_FAVOURITE"
                  class="favourtie-black cursor-pointer"
                  @click.stop.prevent="removeFavourite(item.url)"
                >
                  <img
                    src="@/assets/icons/favourite_active.svg"
                    alt="favourite-black-icon"
                    class="size-4 max-w-none"
                  />
                </span>
              </div>
              <div
                v-if="item.params && item.params.price && item.params.state"
                class="flex items-center justify-between"
              >
                <p class="text-base font-medium leading-5 text-red-500">
                  {{ item.params.price }} zł
                </p>
                <p
                  class="text-xs font-normal uppercase leading-4 text-grey-600"
                >
                  {{ item.params.state }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <p v-else class="p-4 text-sm text-gray-500">
          {{ $t("product_drawer.sub_text") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import { MAGIC_REMOVE_FAVOURITE } from "@/constants/eventsConstants";
import { mapGetters, mapActions } from "vuex";
import BaseImage from "@/components/ui/Image.vue";

export default {
  name: "FavouritesPage",
  components: {
    RouterLink,
    BaseImage,
  },
  data() {
    return {
      MAGIC_REMOVE_FAVOURITE,
      product: null,
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters("favourites", ["favourites"]),
  },
  methods: {
    ...mapActions("favourites", ["removeFavourite"]),
    openUrlInNewTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
