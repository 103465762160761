<template>
  <Transition name="slide-left" duration="300">
    <div
      v-if="mainSummary || mainTip"
      class="flex flex-col items-start text-sm transition-all duration-300 ease-in-out"
    >
      <MagicHelperBubble v-if="mainSummary">
        <Markdown :text="mainSummary" />
        <MagicHelperQuestion />
      </MagicHelperBubble>
      <MagicHelperOnboarding v-if="mainTip" :tip="mainTip" />
    </div>
  </Transition>
</template>
<script>
import Markdown from "@/components/ui/Markdown.vue";
import { mapGetters } from "vuex";
import MagicHelperBubble from "./MagicHelperBubble.vue";
import MagicHelperOnboarding from "./MagicHelperOnboarding.vue";
import MagicHelperQuestion from "./MagicHelperQuestion.vue";

export default {
  name: "MagicHelperMain",
  components: {
    Markdown,
    MagicHelperQuestion,
    MagicHelperBubble,
    MagicHelperOnboarding,
  },
  computed: {
    ...mapGetters("hybridHelper", ["mainSummary"]),
    ...mapGetters("hybridOnboarding", ["mainTip"]),
  },
};
</script>
