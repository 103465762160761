<template>
  <MenuItem class="z-20">
    <button
      v-track:click="MAGIC_MY_OLX_LOGOUT"
      :class="
        $twMerge(
          'flex w-full items-center gap-2 px-4 py-2',
          'text-sm text-gray-700 hover:bg-gray-100',
        )
      "
      @click="logout"
    >
      <img class="" src="@/assets/icons/log_out.svg" alt="Log Out" />
      <div class="mt-1">
        {{ $t("navbar.menu.logout") }}
      </div>
    </button>
  </MenuItem>
</template>

<script>
import { MAGIC_MY_OLX_LOGOUT } from "@/constants/eventsConstants";
import { MenuItem } from "@headlessui/vue";

export default {
  components: {
    MenuItem,
  },
  data() {
    return {
      MAGIC_MY_OLX_LOGOUT,
    };
  },
  methods: {
    logout() {
      this.$auth0.logout({
        clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
        openUrl: async (url) => {
          window.location.assign(url);
        },
        logoutParams: {
          logout_uri: window.location.origin,
          returnTo: window.location.origin,
        },
      });
    },
  },
};
</script>
