<template>
  <div
    v-scroll-to-element
    :class="
      $twMerge(
        'container mx-auto flex scroll-mt-32 flex-col',
        'items-stretch p-2 md:flex-row md:gap-6 md:p-0',
      )
    "
  >
    <div class="lg-full-page-content overflow-auto lg:w-2/3">
      <div
        :class="
          $twMerge(
            'z-10 flex h-10 items-center',
            'gap-2 md:sticky md:top-0 md:h-16 md:bg-gray-100',
            'overflow-hidden',
          )
        "
      >
        <div
          class="line-clamp-1 flex shrink-0 cursor-pointer items-center"
          @click="goBack"
        >
          <img
            src="@/assets/icons/left_arrow.svg"
            class="mb-1 ml-2 inline size-5"
            alt="Back Button"
          />
          <span v-if="!isMagicHybrid">{{
            $t("product.breadcrumb_conversation")
          }}</span>
        </div>
        <div class="line-clamp-1 text-ellipsis pb-1">
          {{ product?.title }}
        </div>
      </div>

      <!-- Pictures -->
      <div
        v-if="error"
        :class="
          $twMerge(
            'flex h-96 w-full items-center justify-center',
            'bg-slate-200 text-center text-slate-800',
          )
        "
      >
        <span class="px-8 text-center md:w-1/2">
          {{ $t("product.error_loading") }}
        </span>
      </div>
      <ProductGallery v-else :images="photos" :loading="loading" />
      <!-- Product description -->
      <ProductDescription class="grow" />
    </div>

    <!-- Base info -->
    <div
      class="lg-full-page-content flex min-w-80 flex-col gap-0.5 overflow-auto pb-4 md:pt-16 lg:w-1/3"
    >
      <template v-if="loading">
        <div class="h-40 w-full animate-pulse bg-slate-300"></div>
      </template>

      <template v-else>
        <div class="hidden flex-col gap-3 bg-white p-4 md:flex">
          <div class="text-sm font-normal">
            {{ location || $t("product.no_location") }}
            <span v-if="time">{{ " - " + time }}</span>
          </div>
          <h2 class="text-xl font-normal">{{ product?.title }}</h2>
          <div class="flex items-center justify-between">
            <span class="text-lg font-medium">{{ price }}</span>
            <span
              class="cursor-pointer"
              @click.stop.prevent="toggleFavourite(item_for_favoutite, $event)"
            >
              <img
                :src="
                  isFavourite(item_for_favoutite)
                    ? require('@/assets/icons/favourite_active.svg')
                    : require('@/assets/icons/favourite-black.svg')
                "
                alt="favourite-black-icon"
                class="size-8 max-w-none"
              />
            </span>
          </div>
          <ProductButton
            v-if="product?.url"
            :product="product"
            class="w-full grow"
          />
        </div>
      </template>
    </div>

    <!-- Sticky Button for Mobile -->
    <div
      v-if="product?.url"
      class="sticky bottom-0 right-0 w-full bg-white p-4 shadow-box md:hidden"
    >
      <div
        :class="
          $twMerge(
            'flex flex-col gap-3 md:flex-row',
            'md:items-center md:justify-between',
          )
        "
      >
        <div class="text-sm font-normal">
          {{ location || $t("product.no_location") }}
          <span v-if="time">{{ " - " + time }}</span>
        </div>
        <h2 class="text-xl font-normal">{{ product?.title }}</h2>
        <div class="flex items-center justify-between">
          <span class="text-lg font-medium">{{ price }}</span>
          <span
            class="cursor-pointer"
            @click.stop.prevent="toggleFavourite(item_for_favoutite, $event)"
          >
            <img
              :src="
                isFavourite(item_for_favoutite)
                  ? require('@/assets/icons/favourite_active.svg')
                  : require('@/assets/icons/favourite-black.svg')
              "
              alt="favourite-black-icon"
              class="size-8 max-w-none"
            />
          </span>
        </div>
        <ProductButton
          v-if="product?.url"
          :product="product"
          class="w-full grow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductGallery from "./ProductGallery.vue";
import ProductDescription from "./ProductDescription.vue";
import { getAd } from "@/services/AdsService";
import { format } from "date-fns";
import TrackingService from "../../services/TrackingService";
import ProductButton from "./ProductButton";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isFromMagicHybrid } from "./utils";

export default {
  name: "ProductPage",
  components: {
    ProductGallery,
    ProductDescription,
    ProductButton,
  },
  data() {
    return {
      product: null,
      loading: true,
      error: null,
      item_for_favoutite: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    photos() {
      return (
        this.product?.photos?.map((p) =>
          p.link.replace("{width}", p.width).replace("{height}", p.height),
        ) ?? []
      );
    },
    location() {
      const values = [
        this.product?.location?.city?.name,
        this.product?.location?.region?.name,
      ].filter((v) => !!v);
      return values.length ? values.join(", ") : null;
    },
    price() {
      return this.product?.params?.find((p) => p.key === "price")?.value?.label;
    },
    time() {
      const d = this.product?.last_refresh_time ?? this.product?.created_time;
      return d ? format(new Date(d), "dd/MM/yyyy HH:mm") : null;
    },
    ...mapGetters("favourites", ["favourites"]),
    isMagicHybrid() {
      return isFromMagicHybrid();
    },
  },
  mounted() {
    const item_position = this.$route.query.item_position;
    this.fetchAd();

    if (isFromMagicHybrid()) {
      this.USER_CLICKED_PRODUCT({ type: this.$route.query.ad_source });
    }

    const itemInfoEvent = isFromMagicHybrid()
      ? this.$EVENTS.HYBRID.MAGIC_ITEM_INFO
      : {
          ...this.$EVENTS.CORE.MAGIC_ITEM_INFO,
          ad_id: this.id,
          ad_position: item_position,
        };

    TrackingService.trackPage(itemInfoEvent);
  },
  methods: {
    ...mapActions("favourites", ["addFavourite", "removeFavourite"]),
    ...mapMutations("hybridOnboarding", ["USER_CLICKED_PRODUCT"]),
    isFavourite(item) {
      return this.favourites.some((favourite) => favourite.url === item.url);
    },
    toggleFavourite(item) {
      const eventType = isFromMagicHybrid() ? "HYBRID" : "CORE";
      const eventAction = this.isFavourite(item)
        ? "MAGIC_REMOVE_FAVOURITE"
        : "MAGIC_ADD_FAVOURITE";

      this.$tracking.trackEvent("click", this.$EVENTS[eventType][eventAction]);

      if (this.isFavourite(item)) {
        this.removeFavourite(item.url);
      } else {
        this.addFavourite(item);
      }
    },
    async fetchAd() {
      try {
        if (this.product?.id === this.id) return;

        this.loading = true;
        this.error = null;
        const ad = await getAd(this.id);
        this.loading = false;
        this.product = ad;

        const customFavourite = {
          description: ad.description,
          id: ad.id,
          photos:
            ad?.photos?.map((p) =>
              p.link.replace("{width}", p.width).replace("{height}", p.height),
            ) ?? [],
          isFavourite: false,
          isSelected: false,
          title: ad.title,
          params: {
            price: ad.params[0]?.value?.label || null,
            state: ad.params[3]?.value?.key || null,
          },
          url: `/buy/${ad.id}`,
        };

        this.item_for_favoutite = customFavourite;
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>
