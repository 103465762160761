<template>
  <div class="container mx-auto mt-8 flex justify-center">
    <div class="flex w-full flex-col md:w-1/2">
      <DownloadAppLinks />
    </div>
  </div>
</template>
<script>
import DownloadAppLinks from "./DownloadAppLinks.vue";
import {
  VUE_APP_APP_STORE_LINK,
  VUE_APP_PLAY_STORE_LINK,
} from "@/constants/storeLinks";

export default {
  name: "DownloadAppPage",
  components: {
    DownloadAppLinks,
  },
  data() {
    return {
      LINK: `${process.env.VUE_APP_API_URL ?? ""}/api/v1/mobile-app/download`,
    };
  },
  mounted() {
    if (!VUE_APP_APP_STORE_LINK && !VUE_APP_PLAY_STORE_LINK) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
