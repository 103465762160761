import store from "@/store";

export function getNinjaConfig() {
  const commonConfig = {
    platform: "w",
    siteUrl: "www.olx.pl",
    region: "cee",
    plugins: ["LQ"],
    asyncSurveyStyles: true,
    surveyTheme: "cee",
  };

  const enSurveyTexts = {
    accept: "Sure",
    checkBoxGroupHint: "Select one or more options",
    decline: "Not now",
    finish: "Finish",
    multiLineInputHint: "Add a comment",
    next: "Next",
    policyLink:
      'Please check out the <a href="https://pomoc.olx.pl/olxplhelp/s/article/polityka-prywatno%C5%9Bci-V27-olx" target="_blank">OLX policy</a> regarding data protection',
    radioGroupHint: "Select one option",
    singleInputHint: "Add a comment",
  };

  const plSurveyTexts = {
    accept: "Tak, pewnie",
    checkBoxGroupHint: "Możesz wybrać kilka odpowiedzi",
    decline: "Nie, dziękuję",
    finish: "Gotowe",
    multiLineInputHint: "Wpisz swoją odpowiedź",
    next: "Dalej",
    policyLink:
      'Przeczytaj naszą <a href="https://pomoc.olx.pl/olxplhelp/s/article/polityka-prywatno%C5%9Bci-V27-olx" target="_blank">Politykę prywatności</a>, aby dowiedzieć się, jak chronimy Twoje dane',
    radioGroupHint: "Wybierz tylko jedną odpowiedź",
    singleInputHint: "Wpisz swoją odpowiedź",
  };

  const localeSurveyTexts =
    store?.state?.settings?.selectedLocale === "EN"
      ? enSurveyTexts
      : plSurveyTexts;

  return {
    ...commonConfig,
    surveys: localeSurveyTexts,
  };
}
