<template>
  <div class="w-full p-2">
    <h2
      class="relative mb-2 text-base font-medium uppercase tracking-wider text-magic-800"
    >
      <!-- Logo -->
      <TitleLead
        class="mb-0.5 ml-3 mr-1 inline"
        :class="{ ' text-red-500': variant === 'error' }"
      />
      {{ $t("search.helper.main_summary_title") }}

      <!-- Triangle pointing up -->
      <div class="relative">
        <div
          :class="
            $twMerge(
              'absolute -bottom-2.5 left-[12px] h-0 w-0 z-20',
              'border-x-[8px] border-b-[9.5px] border-transparent',
              variant === 'main' && 'border-b-teal-200',
              variant === 'error' && 'border-b-red-200',
            )
          "
        ></div>
        <!-- Outer border div -->
        <div
          :class="
            $twMerge(
              'absolute -bottom-2.5 left-[11px] h-0 w-0',
              'border-x-[9px] border-b-[11px] border-transparent',
              'border-b-grey-400',
            )
          "
        ></div>
      </div>
    </h2>

    <!-- Content -->
    <div
      class="relative z-10 w-full rounded border border-solid border-grey-400 p-2"
      :class="{
        'bg-teal-200 text-teal-950': variant === 'main',
        'bg-red-400/35 text-red-950': variant === 'error',
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import TitleLead from "@/components/ui/icons/TitleLead.vue";

export default {
  name: "MagicHelperMain",
  components: {
    TitleLead,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "main",
    },
  },
};
</script>

<style scoped>
::v-deep .markdown-p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
