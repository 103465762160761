import { getUsersMe } from "@/services/UsersService";
import logger from "@/services/logger";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    FETCH_USER({ commit }) {
      getUsersMe()
        .then((response) => commit("SET_USER", response?.data))
        .catch((e) => logger.error(e));
    },
  },
  getters: {
    user: (state) => state.user,
    userName: ({ user }) => user?.person,
    userId: ({ user }) => user?.id,
  },
};
