<template>
  <img
    :src="currentSrc"
    :class="className"
    :alt="alt"
    v-bind="$attrs"
    @error="handleError"
  />
</template>

<script>
import defaultImage from "@/assets/default-image.jpg";
import logger from "@/services/logger";

export default {
  name: "BaseImage",
  props: {
    src: {
      type: String,
      default: null,
    },
    class: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentSrc: this.isValidUrl(this?.src) ? this?.src : defaultImage,
    };
  },
  computed: {
    className() {
      return this.class;
    },
  },
  watch: {
    src(newSrc) {
      if (this.isValidUrl(newSrc)) {
        this.currentSrc = newSrc;
      } else {
        this.currentSrc = defaultImage;
      }
    },
  },
  methods: {
    handleError() {
      logger.log("Image failed to load, using default image");
      this.currentSrc = defaultImage;
    },
    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    },
  },
};
</script>
