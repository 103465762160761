<template>
  <AppNavbar />
  <router-view></router-view>
  <div
    v-if="refreshing"
    class="fixed bottom-4 left-1/2 z-50 flex h-12 w-96 -translate-x-1/2 items-center justify-center gap-2 rounded bg-grey-700 px-4 py-3.5 text-sm font-normal leading-4 text-white underline-offset-2"
  >
    <img src="@/assets/icons/refresh.svg" alt="Refresh Icon" />
    <p>{{ $t("refresh.text") }}</p>
    <span class="cursor-pointer underline" @click="refreshPage">{{
      $t("refresh.button")
    }}</span>
  </div>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import AppNavbar from "./components/navbar/AppNavbar.vue";
import { registerCookieConsentListener } from "./store/persistance/initialise";
import "./utils/marked";

export default {
  name: "App",
  components: {
    AppNavbar,
  },
  data() {
    return {
      refreshing: false,
      registration: null,
    };
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    this.loadExternalScripts();
    this.initAppData();
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.refreshing = true;
    },
    refreshPage() {
      location.reload();
    },
    loadExternalScripts() {
      if (process.env.VUE_APP_STAGE === "local") return;
      loadScript(
        `/vendor/new-relic_${process.env.VUE_APP_STAGE?.toLowerCase()}.js`,
      );
    },
    initAppData() {
      this.$store.dispatch("user/FETCH_USER");
      registerCookieConsentListener(this.$store);
    },
  },
};
</script>
