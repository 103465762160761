module.exports = {
  content: ["./index.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
  darkMode: "media", // or 'media' or 'class'
  theme: {
    container: {
      center: true,
      padding: "1rem",
      screens: {
        lg: "1024px",
        xl: "1240px",
        "2xl": "1240px",
      },
    },
    extend: {
      fontFamily: {
        olx: ["Geomanist", "sans-serif"],
      },
      lineHeight: {
        4.5: "1.125rem",
      },
      backgroundSize: {
        "size-200": "200% 200%",
      },
      backgroundPosition: {
        "pos-0": "0% 0%",
        "pos-100": "100% 100%",
      },
      borderWidth: {
        1: "1px",
      },
      colors: {
        grey: {
          100: "#FFFFFF",
          200: "#FAFBFB",
          300: "#F2F4F5",
          400: "#D8DFE0",
          500: "#7F9799",
          600: "#406367",
          700: "#002F34",
        },
        teal: {
          100: "#E9FCFB",
          200: "#C8F8F6",
          300: "#91F2ED",
          400: "#5AECE4",
          500: "#23E5DB",
          600: "#00A49F",
          700: "#085C5D",
        },
        yellow: {
          100: "#FFFBEF",
          200: "#FFFD69",
          300: "#FFEDB2",
          400: "#FFE48D",
          500: "#FFCE32",
          600: "#D2B982",
          700: "#71674E",
        },
        blue: {
          100: "#EBF1FF",
          200: "#CEDDFF",
          300: "#9CBBFF",
          400: "#6C99FF",
          500: "#3A77FF",
          600: "#1D3C81",
          700: "#17284E",
        },
        red: {
          100: "#FFEEEA",
          200: "#FFD6C9",
          300: "#FFAA9A",
          400: "#FF8169",
          500: "#FF5636",
          600: "#D02C0D",
          700: "#991801",
        },
        magic: {
          50: "#D3FAF8",
          100: "#B6F6F3",
          200: "#91F2ED",
          300: "#6CEEE7",
          400: "#48E9E1",
          500: "#23E5DB",
          600: "#1DBFB7",
          700: "#179992",
          800: "#12736E",
          900: "#0C4C49",
          950: "#072E2C",
        },
      },
      height: {
        8.5: "2.125rem",
        18: "4.5rem",
        112: "28rem",
      },
      margin: {
        176: "44rem",
      },
      backdropBlur: {
        xs: "2px",
        vlg: "20px",
      },
      boxShadow: {
        box: "0px -4px 16px 0px rgba(0, 0, 0, 0.15)",
        inputBox: "-2px -2px 12px 0px rgba(0, 0, 0, 0.25)",
        custom: "0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1)",
        "custom-product": "2px 4px 16px 0px rgba(0, 0, 0, 0.15)",
      },
      inset: {
        "10p": "10%",
      },
      backgroundImage: {
        "text-gradient":
          "linear-gradient(91deg, #085C5D 0.25%, #23E5DB 46.02%, #1976D2 99.75%)",
        "border-gradient": "linear-gradient(90deg, #003E3C 0%, #00A49F 100%)",
      },
      animation: {
        "scale-up-down": "scale-up-down 1s ease-in-out infinite",
      },
      spacing: {
        "5/8": "62.5%",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require("@tailwindcss/forms"), // Add the forms plugin here
  ],
};
