<template>
  <Transition name="fade" duration="300">
    <div
      v-if="src || fileReading"
      class="relative h-8 w-12 cursor-pointer transition-all duration-300"
      @click="onClear"
    >
      <div
        v-if="uploadedImage"
        class="absolute -top-1 right-0 z-20 size-3 rounded-full bg-teal-700"
      >
        <CrossIcon class="size-3 text-white" />
      </div>
      <img
        v-show="imageLoaded"
        :src="src"
        class="h-8 w-12 object-cover transition-all duration-300"
        alt="Preview Image"
        @load="imageLoaded = true"
      />
      <div
        v-if="loading || fileReading"
        class="absolute inset-0 z-10 animate-pulse bg-black/50 transition-all"
      ></div>
    </div>
  </Transition>
</template>

<script>
import CrossIcon from "@/components/ui/icons/CrossIcon.vue";
import { mapGetters } from "vuex";

export default {
  name: "SearchBarImagePreview",

  components: {
    CrossIcon,
  },
  props: {
    fileReading: {
      type: Boolean,
      default: false,
    },
    previewImage: {
      type: String,
      default: undefined,
    },
  },
  emits: ["file-cleared"],
  data() {
    return {
      imageLoaded: false,
    };
  },
  computed: {
    ...mapGetters("search", ["queryImage", "uploadedImage"]),
    src() {
      return this.uploadedImage || this.previewImage;
    },
    loading() {
      return this.previewImage
        ? !this.uploadedImage && !this.imageLoaded
        : false;
    },
  },
  watch: {
    previewImage: {
      immediate: true,
      handler() {
        this.imageLoaded = false;
      },
    },
  },
  methods: {
    onClear() {
      this.$emit("file-cleared");
    },
  },
};
</script>
