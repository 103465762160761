<template>
  <div
    v-track:click="{
      ...MAGIC_SEARCH_DEFAULT_PROMPT,
      magic_card_type: example.text,
    }"
    class="flex cursor-pointer flex-col items-start gap-6 rounded-lg bg-grey-700 p-4 shadow-md"
    @click="clickHandler"
  >
    <div
      class="flex min-h-10 w-10 items-center justify-center rounded-full bg-white"
    >
      <img :src="example.src" class="size-6" alt="Example Icon" />
    </div>
    <p
      class="mt-auto line-clamp-3 h-1/2 overflow-hidden text-left tracking-wide text-white"
    >
      {{ $t(example.locale_text) }}
    </p>
  </div>
</template>

<script>
import { MAGIC_SEARCH_DEFAULT_PROMPT } from "@/constants/eventsConstants";

export default {
  props: {
    example: {
      type: Object,
      required: true,
    },
  },
  emits: ["exampleSelected"],
  data() {
    return {
      MAGIC_SEARCH_DEFAULT_PROMPT,
    };
  },
  methods: {
    clickHandler() {
      const e = this.example;
      this.$emit("exampleSelected", {
        type: e.type,
        prompt_text: e.prompt_text_label
          ? this.$t(e.prompt_text_label)
          : undefined,
        prompt_key: e.prompt_key_label
          ? this.$t(e.prompt_key_label)
          : undefined,
      });
    },
  },
};
</script>
