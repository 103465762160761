<template>
  <div>
    <div
      v-if="compareLoading && !compareResult"
      class="mt-6 flex items-center justify-center py-6"
    >
      <Loader />
    </div>
    <p v-if="compareError && !compareResult">
      {{ $t("magic_search_two_point.modal.smart_compare_error") }}
    </p>
    <Markdown v-if="compareResult" :text="compareResult" />
  </div>
</template>

<script>
import Loader from "@/components/ui/Loader.vue";
import Markdown from "@/components/ui/Markdown.vue";
import { mapGetters } from "vuex";

export default {
  name: "SmartCompareResult",
  components: {
    Markdown,
    Loader,
  },
  computed: {
    ...mapGetters("hybridActions", [
      "compareResult",
      "compareLoading",
      "compareError",
    ]),
  },
};
</script>
