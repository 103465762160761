<template>
  <div
    class="relative"
    @mouseenter="startSlideshow"
    @mouseleave="stopSlideshow"
  >
    <div
      class="relative size-40 overflow-hidden transition-transform duration-200 hover:scale-105"
    >
      <BaseImage
        :src="images[currentImage]"
        alt="carousel image"
        class="size-full border border-white object-cover transition-transform duration-500 ease-in-out"
      />
      <div
        v-if="showDots"
        class="absolute inset-x-0 bottom-0 flex h-6 items-center justify-center space-x-1 bg-white px-2 pt-2"
      >
        <span
          v-for="(image, index) in images"
          :key="index"
          @mouseenter="changeImage(index)"
        >
          <img
            :alt="
              index === currentImage
                ? 'current-pagination-icon'
                : 'pagination-icon'
            "
            class="!m-0 size-2 border-transparent"
            :src="
              index === currentImage
                ? require('@/assets/icons/current_pagination.svg')
                : require('@/assets/icons/pagination.svg')
            "
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/ui/Image.vue";

export default {
  name: "Carousel",
  components: {
    BaseImage,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentImage: 0,
      slideshowInterval: null,
      showDots: false,
    };
  },
  methods: {
    nextImage() {
      this.currentImage = (this.currentImage + 1) % this.images.length;
    },
    startSlideshow() {
      this.showDots = true;
      this.slideshowInterval = setInterval(this.nextImage, 2000);
    },
    stopSlideshow() {
      this.showDots = false;
      clearInterval(this.slideshowInterval);
    },
    changeImage(index) {
      this.currentImage = index;
    },
  },
};
</script>

<style scoped>
/* Additional global styles can be added here */
</style>
