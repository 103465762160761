<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.827 10.2383L14.0279 9.73292C14.6503 8.16699 14.5632 6.36483 13.6469 4.81297C12.0448 2.0997 8.47445 1.14329 5.65667 2.73405C2.83889 4.32481 1.90076 7.82648 3.5028 10.5397C5.09509 13.2365 8.63179 14.1977 11.4413 12.6475L12.041 12.3166L12.7164 12.4649L14.4243 12.8398L13.9492 10.7704L13.827 10.2383ZM16.3739 13.2678L16.8138 15.1838L14.8643 14.7559L12.3385 14.2014C8.68424 16.2176 4.04276 14.9832 1.94004 11.422C-0.175544 7.83897 1.07994 3.25739 4.74423 1.18873C8.40853 -0.879929 13.0941 0.347705 15.2096 3.93072C16.4183 5.97769 16.5266 8.35056 15.7142 10.3946L16.3739 13.2678ZM6.96169 9.17319C6.23228 8.46107 5.3798 8.11007 4.37407 8.11007V7.23542C5.3798 7.23542 6.23228 6.88442 6.96169 6.1723C7.68953 5.46171 8.06593 4.60755 8.12158 3.57696L8.97399 3.57999C9.00574 4.25241 9.18688 4.8603 9.53248 5.42148L9.53336 5.42292C9.87172 5.9771 10.3277 6.41995 10.9003 6.75312C11.4685 7.07492 12.0733 7.23542 12.7389 7.23542V8.11007C11.7322 8.11007 10.8712 8.46175 10.1425 9.17319L10.1408 9.17481C9.40344 9.887 9.0214 10.7403 8.974 11.7563H8.12142C8.074 10.74 7.69163 9.88583 6.96169 9.17319Z"
      fill="#02282C"
    />
  </svg>
</template>
