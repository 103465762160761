<template>
  <div
    v-if="visible"
    :class="
      $twMerge(
        'fixed left-1/2 top-20 z-50 w-11/12',
        '-translate-x-1/2 rounded bg-yellow-100',
        'px-4 py-3 text-grey-700 shadow-md',
        'sm:w-2/3 md:w-1/2 lg:w-1/3',
      )
    "
    role="alert"
  >
    <div :class="$twMerge('flex flex-col justify-between')">
      <div :class="$twMerge('flex items-start')">
        <img src="@/assets/icons/alert.svg" alt="Alert Icon" />
        <div :class="$twMerge('ml-3')">
          <p :class="$twMerge('text-grey-700 text-sm font-medium')">
            {{ $t("custom_alert.messageTitle") }}
          </p>
          <p :class="$twMerge('text-grey-700 mt-1 text-sm')">
            {{ $t("custom_alert.message") }}
          </p>
        </div>
      </div>
      <div :class="$twMerge('mt-4 flex justify-end')">
        <button
          :class="
            $twMerge(
              'border-0 bg-transparent text-lg font-bold',
              'text-grey-700 underline underline-offset-4',
            )
          "
          @click="closeAlert"
        >
          {{ $t("custom_alert.btn_text") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomAlert",
  emits: ["alert-closed"],
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    closeAlert() {
      this.visible = false;
      this.$emit("alert-closed");
    },
    openAlert() {
      this.visible = true;
    },
  },
};
</script>
