<template>
  <Transition name="slide-left" duration="300">
    <ProductGroup
      v-if="instantProducts && instantProducts.length"
      :title="$t('search.instant_results_header')"
      :products="instantProducts"
      source="instant_filter"
      class="transition-all duration-300 ease-in-out"
    />
  </Transition>
</template>
<script>
import { mapGetters } from "vuex";
import ProductGroup from "./products/ProductGroup.vue";
export default {
  name: "SearchInstantResults",
  components: {
    ProductGroup,
  },
  computed: {
    ...mapGetters("search", ["instantProducts"]),
  },
};
</script>
