<template>
  <button
    v-if="!isAuthenticated"
    v-track:click="MAGIC_HOMEPAGE_LOGIN"
    :class="
      $twMerge(
        'rounded bg-white px-4 py-2 font-medium',
        'text-grey-700 hover:bg-gray-200',
      )
    "
    @click="login"
  >
    {{ $t("navbar.login") }}
  </button>
</template>

<script>
import { MAGIC_HOMEPAGE_LOGIN } from "@/constants/eventsConstants";

export default {
  data() {
    return {
      MAGIC_HOMEPAGE_LOGIN,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$auth0.isAuthenticated.value;
    },
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    },
  },
};
</script>
