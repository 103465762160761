<template>
  <div>
    <div class="flex justify-end">
      <BaseButton v-if="!compareView" variant="secondary" @click="toggleView">
        <img src="@/assets/icons/compare.svg" alt="compare" />
        <span>
          {{ $t("compare.title") }}
        </span>
      </BaseButton>
    </div>
    <div v-if="compareView" class="flex justify-end gap-2">
      <BaseButton variant="secondary" @click="handleCancel">
        <img src="@/assets/icons/cross.svg" alt="compare" />
        <span>
          {{ $t("compare.button_cancel") }}
        </span>
      </BaseButton>
      <BaseButton
        variant="secondary"
        :disabled="selected.length <= 1"
        @click="handleClick"
      >
        <img src="@/assets/icons/right.svg" alt="improve" />
        <span>
          {{ $t("compare.confirm") }}
        </span>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import { MAGIC_SMART_COMPARE } from "@/constants/eventsConstants";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SmartCompare",
  components: {
    BaseButton,
  },
  emits: ["compareView"],
  data() {
    return {
      MAGIC_SMART_COMPARE,
      compareView: false,
    };
  },
  computed: {
    ...mapGetters("selected", ["selected"]),
  },
  methods: {
    ...mapActions("selected", ["compareSelected", "clearSelected"]),
    handleClick() {
      if (!this.isDisabled) {
        this.$tracking.trackEvent("click", MAGIC_SMART_COMPARE);
        this.compareSelected();
        this.toggleView();
      }
    },
    handleCancel() {
      this.clearSelected();
      this.toggleView();
    },
    toggleView() {
      this.compareView = !this.compareView;
      this.$emit("compareView", this.compareView);
    },
  },
};
</script>
