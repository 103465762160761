<template>
  <div v-if="clarifyingQuestion" class="flex w-full flex-col gap-2 text-sm">
    <div class="flex items-center justify-between">
      <h2 class="m-0 font-normal leading-5">
        {{ clarifyingQuestion }}
      </h2>
    </div>
    <div
      :class="
        $twMerge(
          'relative flex rounded-lg border h-9',
          'border-solid border-grey-400 bg-white',
        )
      "
    >
      <input
        ref="messageInput"
        v-model="message"
        type="text"
        :placeholder="$t('magic_search_two_point.modal.placeholder_three')"
        :class="
          $twMerge(
            'mx-2 w-full items-center rounded text-sm',
            'border-none p-0 font-normal leading-5',
            'focus:outline-none focus:ring-0',
          )
        "
        @keyup.enter="onRefineSearch"
      />
      <button
        class="flex h-full items-center justify-center px-3"
        :class="{ 'opacity-50': !message.length }"
        :disabled="!message.length"
        @click="onRefineSearch"
      >
        <FilterIcon class="size-4 shrink-0" />
      </button>
    </div>
    <div
      v-if="clarifyingQuestionSuggestions?.length"
      class="olx-horizontal-scrollbar flex gap-2 overflow-x-auto pb-2 pr-4"
    >
      <BaseButton
        v-for="option in clarifyingQuestionSuggestions"
        :key="option"
        v-track:click="$EVENTS?.HYBRID?.MAGIC_HELPER_ANSWER_SELECTED"
        variant="primary"
        class="shrink-0"
        @click="onRefine($event, option)"
      >
        {{ option }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/ui/BaseButton.vue";
import FilterIcon from "@/components/ui/icons/FilterIcon.vue";

export default {
  name: "MagicHelperQuestion",
  components: { BaseButton, FilterIcon },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    ...mapGetters("hybridHelper", [
      "clarifyingQuestion",
      "clarifyingQuestionSuggestions",
    ]),
  },
  methods: {
    ...mapActions("hybridActions", ["refine"]),
    onRefineSearch(event, message) {
      this.$tracking.trackEvent(
        "click",
        this.$EVENTS?.HYBRID?.MAGIC_HELPER_INPUT,
      );
      this.onRefine(event, message);
    },
    onRefine(event, message) {
      this.refine({
        message: message ?? this.message,
        question: this.clarifyingQuestion,
      });
    },
  },
};
</script>
