<template>
  <div
    :class="$twMerge('relative flex flex-wrap gap-2 md:py-4')"
    translate="no"
  >
    <button
      v-for="response in followUpResponses"
      :key="response"
      v-track:click="MAGIC_SUGGESTED_PROMPT_CLICK"
      class="follow-up-card"
      :class="
        $twMerge(
          'cursor-pointer rounded text-sm',
          'border border-solid border-grey-500',
          'px-4 py-2 text-left text-grey-700',
          'transition duration-200',
          'hover:bg-blue-50',
        )
      "
      @click="handleSuggestion(response)"
    >
      {{ response }}
    </button>
  </div>
</template>

<script>
import { MAGIC_SUGGESTED_PROMPT_CLICK } from "@/constants/eventsConstants";
import { mapState } from "vuex";

export default {
  name: "FollowUpSuggestions",
  data() {
    return {
      MAGIC_SUGGESTED_PROMPT_CLICK,
    };
  },
  computed: {
    ...mapState("messages", ["followUpResponses"]),
  },
  methods: {
    handleSuggestion(response) {
      this.$store.dispatch("messages/sendMessage", {
        text: response,
        isUser: true,
      });
      // Clear follow-up responses when a suggestion is clicked
      this.$store.commit("messages/CLEAR_FOLLOW_UP_RESPONSES");
    },
  },
};
</script>

<style scoped>
.follow-up-card {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
</style>
