<template>
  <div class="flex gap-2">
    <div
      v-for="(_, index) in circles"
      :key="index"
      class="flex size-6 items-center justify-center rounded-full bg-grey-700"
    >
      <div
        class="size-2 animate-scale-up-down rounded-full bg-white transition-all"
        :style="{ 'animation-delay': `${index * 250}ms` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {
      circles: [1, 2, 3],
    };
  },
};
</script>
