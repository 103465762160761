<template>
  <RouterLink
    v-if="
      node.tagName?.toLowerCase() === 'a' &&
      node.attributes?.href?.startsWith('/')
    "
    v-bind="omit(node.attributes, ['target'])"
    :to="node.attributes.href"
  >
    <template v-for="(child, index) in node.children" :key="index">
      <template v-if="!child.tagName">{{ child.text }}</template>
      <MarkdownComponent v-else :node="child" />
    </template>
  </RouterLink>

  <!-- Re-enable once link issues are fixed -->
  <!-- <MarkdownProductLink
    v-else-if="
      node.tagName?.toLowerCase() === 'a' &&
      node.attributes?.href?.startsWith('/buy')
    "
    :id="node.attributes.href.split('/').pop()"
    class="w-full flex justify-items-stretch justify-center mb-4"
  /> -->

  <MarkdownProductHeader
    v-else-if="node.tagName?.toLowerCase() == 'code'"
    :text="node.children?.[0]?.text"
  />

  <template v-else>
    <component
      :is="node.tagName"
      v-bind="node.attributes"
      :class="{ 'markdown-image': node.tagName?.toLowerCase() === 'img' }"
    >
      <template v-for="(child, index) in node.children" :key="index">
        <template v-if="!child.tagName">{{ child.text }}</template>
        <MarkdownComponent v-else :node="child" />
      </template>
    </component>
  </template>
</template>

<script>
import MarkdownProductHeader from "./MarkdownProductHeader.vue";
import MarkdownProductLink from "./MarkdownProductLink.vue";
import ProductButton from "@/components/product-page/ProductButton.vue";

function omit(obj, keys) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key)),
  );
}

export default {
  name: "MarkdownComponent",
  components: {
    ProductButton,
    MarkdownProductHeader,
    MarkdownProductLink,
  },
  props: {
    node: {
      type: Object,
      default: () => ({
        tagName: "",
        attributes: {},
        children: [],
        text: null,
      }),
    },
  },
  computed: {
    textChild() {
      return this.node?.children?.map((c) => c.text).join("") ?? "";
    },
  },
  methods: {
    omit,
  },
};
</script>

<style scoped>
.markdown-image {
  max-width: 100%;
  max-height: 300px;
  width: auto;
  height: auto;
  object-fit: contain;
}

@media (max-width: 640px) {
  .markdown-image {
    max-height: 200px;
  }
}
</style>
