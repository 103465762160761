import store from "@/store";
import { getAccessToken } from "@/auth";
import { getClientSessionParams } from "../auth/magic_auth";
import logger from "@/services/logger";

class WebSocketService {
  constructor() {
    this.socket = null;
    this.onMessageReceived = null;
    this.audio = new Audio();
    this.isPlaying = false;
    this.audioQueue = [];
    this.sentenceBuffer = "";
    this.audio_paused = false;
    this.connectionEstablished = false;
  }

  async sendMessage(message) {
    this.audioQueue = [];
    this.audio.pause();
    this.isPlaying = false;

    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    }
  }

  disconnect() {
    this.socket?.close();
  }

  async verifyConnection(sessionId) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) return;
    await this.connect(sessionId);
  }

  setOnMessageReceived(callback) {
    this.onMessageReceived = callback;
  }

  setCameraModalCallback(callback) {
    this.openCameraModal = callback;
  }

  _commonData() {
    const data = {};
    const { client_s, client_sl } = getClientSessionParams();
    if (client_s) data.client_s = client_s;
    if (client_sl) data.client_sl = client_sl;

    return data;
  }

  async connect(sessionId) {
    const token = await getAccessToken();
    return new Promise((resolve, reject) => {
      if (this.socket && sessionId && this.sessionId === sessionId) return;

      const data = this._commonData();
      const params = { ...data, token };
      const query = new URLSearchParams(params).toString();
      let wsUrl = `${process.env.VUE_APP_API_URL}/api/v1/buy/ws?${query}`
        .replace(/^http/, "ws")
        .replace(/^https/, "wss");

      if (sessionId) {
        wsUrl += `&session_id=${sessionId}`;
      }

      this.socket = new WebSocket(wsUrl);

      this.socket.onmessage = this.onmessage.bind(this);
      this.socket.onerror = (error) => {
        logger.error("WebSocket Error:", error);
      };
      this.socket.onclose = (event) => {
        logger.log("WebSocket connection closed:", event.code, event.reason);
        reject(event.reason);
        // TODO this is bad practice, we should not dispatch actions from a service
        store.dispatch("messages/handleSocketClose", {});
      };
      this.socket.onopen = () => {
        resolve("WebSocket connection opened");
      };
    });
  }

  async waitConnectedStatus() {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (!this.connectionEstablished) return;

        resolve();
        clearInterval(interval);
        clearTimeout(timer);
      });

      const timer = setTimeout(() => {
        clearInterval(interval);
        reject("Connection timeout");
      }, 5000);
    });
  }

  onmessage(event) {
    this.connectionEstablished = true;

    const data = event.data;
    const isTextToSpeechEnabled =
      store.getters["settings/isTextToSpeechEnabled"];
    if (typeof data === "string") {
      const message = JSON.parse(data);
      store.dispatch("messages/receiveMessage", {
        text: message.text,
        messageType: message["message_type"],
        isUser: false,
        products: message["products"] || null,
        options: message["options"] || null,
        title: message["title"] || null,
        feedback: "",
        status: message["status"] || "unknown",
        sessionId: message["session_id"] || null,
        task_status: message["task_status"] || "completed",
      });
      if (
        this.onMessageReceived &&
        !("search_results" in message) &&
        message.text !== "take_photo" &&
        isTextToSpeechEnabled
      ) {
        this.bufferSentence(message.text);
      }

      if (
        this.openCameraModal &&
        "take_photo" === message["text"] &&
        message["message_type"] === "tool_call"
      ) {
        this.openCameraModal();
      }

      if (message.text === "" || message.text === undefined) {
        this.processRemainingBuffer();
      }
    }
  }

  bufferSentence(text) {
    if (text && text.length > 0) {
      text = text.replace(/[*_]/g, "");
      this.sentenceBuffer += text;
      const sentences = this.splitIntoSentences(this.sentenceBuffer);

      // If the last sentence is incomplete, put it back into the buffer
      if (sentences.length > 0) {
        this.sentenceBuffer = sentences.pop();
      }

      sentences.forEach((sentence) => {
        this.enqueueAudio(sentence);
      });
    }
  }

  splitIntoSentences(text) {
    return text.match(/[^.!?]+[.!?]*/g) || [text];
  }

  processRemainingBuffer() {
    if (this.sentenceBuffer) {
      this.enqueueAudio(this.sentenceBuffer);
      this.sentenceBuffer = "";
    }
  }

  enqueueAudio(text) {
    this.audioQueue.push(text);
    if (!this.isPlaying) {
      this.playNextAudio();
    }
  }

  pauseAudio() {
    if (this.isPlaying && !this.audio_paused) {
      this.audio.pause();
      this.audio_paused = true;
      this.isPlaying = false;
    }
  }

  resumeAudio() {
    const messages = store.state.messages.messages;
    if (messages && messages.length > 0 && this.audioQueue.length === 0) {
      const lastMessageText = messages[messages.length - 1].text;
      this.audioQueue.push(lastMessageText);
    }
    if (!this.isPlaying && this.audio_paused && this.audioQueue.length > 0) {
      this.isPlaying = true;
      this.audio_paused = false;
    }
  }

  playNextAudio() {
    if (this.audio_paused) {
      this.isPlaying = false;
      return;
    }
    if (this.audioQueue.length === 0) {
      this.isPlaying = false;
      store.dispatch("messages/updateAudioState", "pause");
      const messages = store.state.messages.messages;
      if (messages && messages.length > 0) {
        const lastMessageText = messages[messages.length - 1].text;
        this.audioQueue.push(lastMessageText);
        this.audio_paused = true;
      } else {
        logger.log("No messages available.");
      }

      return;
    }
    this.isPlaying = true;
  }

  stopAudio() {
    if (this.isPlaying && !this.audio_paused) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audioQueue = [];
      this.isPlaying = false;
    }
  }
}

export default new WebSocketService();
