import { getAccessToken } from "@/auth";
import axios from "axios";
import { handleCancelableRequest } from "./abortControllersService";

export function injectAxiosInterceptors() {
  axios.interceptors.request.use(
    async function (config) {
      // BEFORE REQ

      const token = config.skipAuthToken ? undefined : await getAccessToken();
      config.headers = {
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.VUE_APP_API_URL,
        ...config.headers,
      };
      config.withCredentials = true;

      if (config.cancelable) {
        config.signal = handleCancelableRequest(
          config.cancelableKey ?? config.url,
          config.method,
          config.cancelableNamespace,
        );
      }

      return config;
    },
    function (error) {
      // AFTER RESPONSE ERROR
      return Promise.reject(error);
    },
  );
}
