import { MODULE_NAMES } from "./persisted-modules";

export const persistance = (store) => {
  store.subscribe((mutation, state) => {
    const moduleName = mutation.type.split("/")[0];
    if (!MODULE_NAMES.includes(moduleName)) return;

    const moduleState = state[moduleName];
    const key = getModulePersistanceKey(moduleName);

    localStorage.setItem(key, JSON.stringify(moduleState));
  });
};

export function getModulePersistanceKey(moduleName) {
  return `olx_magic_store_${moduleName}`;
}
