<template>
  <Transition name="fade">
    <div
      v-if="refineModalOpen"
      :class="
        $twMerge(
          'fixed bottom-0 z-50 flex sm:items-center sm:justify-center items-end',
          'bg-black bg-opacity-70 sm:inset-0 size-full',
        )
      "
    >
      <div
        :class="
          $twMerge(
            'flex w-full flex-col rounded-t-lg bg-grey-300 p-4 shadow-lg',
            'transition-all duration-300 sm:w-11/12 sm:max-w-md sm:rounded md:max-w-xl',
          )
        "
      >
        <ModalHeader
          :title="$t('magic_search_two_point.modal_header.refine')"
          @close="onClose"
        />

        <!-- Refine Content -->
        <RefineContent />
      </div>
    </div>
  </Transition>
</template>

<script>
import {
  allowBackgroundScroll,
  blockBackgroundScroll,
} from "@/utils/scrollUtils";
import { mapGetters, mapMutations } from "vuex";
import ModalHeader from "./ModalHeader";
import RefineContent from "./RefineContent";

export default {
  name: "RefineModal",
  components: { ModalHeader, RefineContent },
  computed: {
    ...mapGetters("hybridActions", ["refineModalOpen"]),
  },
  watch: {
    refineModalOpen(value) {
      if (value) {
        blockBackgroundScroll();
        this.$nextTick(() => {
          if (this.$refs.messageInput) {
            this.$refs.messageInput.focus();
          }
        });
      } else {
        allowBackgroundScroll();
      }
    },
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_REFINE_MODAL_OPEN"]),
    onClose() {
      this.SET_REFINE_MODAL_OPEN(false);
    },
  },
};
</script>
