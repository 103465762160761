<template>
  <div
    ref="root"
    class="no-scrollbar flex w-full items-center gap-4 overflow-y-hidden overflow-x-visible"
    @wheel.prevent
    @touchmove.prevent
    @scroll.prevent
    @mouseover="onPauseAutoScroll"
    @mouseleave="onResumeAutoScroll"
    @touchstart="onPauseAutoScroll"
    @touchend="onResumeAutoScroll"
  >
    <div
      v-for="(example, i) in examples"
      :key="example?.locale_text ?? i"
      class="shrink-0 transition-all"
      :class="{
        'h-44 w-full text-xl leading-6 opacity-80 md:w-72': i === active,
        'h-36 w-full text-sm leading-4 opacity-20 md:w-64': i !== active,
      }"
      @click="clickHandler(example, i)"
    >
      <ExampleCard
        v-if="example"
        :example="example"
        class="size-full shrink-0 grow-0"
        @example-selected="$emit('example-selected', $event)"
      />
    </div>
  </div>
</template>

<script>
import { examples } from "../../examples-data";
import debounce from "lodash.debounce";
import ExampleCard from "./ExampleCard.vue";

const SLIDE_INTERVAL = 5000;
const SIDE_GAP = 2;

export default {
  name: "ExamplesGallery",
  components: {
    ExampleCard,
  },
  props: {
    isFocused: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["example-selected"],
  data() {
    return {
      active: null,
      examples: [],
      pauseAutoScroll: false,
      interval: null,
      resizeHandler: null,
    };
  },
  computed: {
    screenWidth: function () {
      return this.$store.state.windowSize.windowWidth;
    },
  },
  watch: {
    active() {
      this.scrollActiveToMiddle();
    },
    isFocused(newVal) {
      this.pauseAutoScroll = newVal;
      this.resetInterval();
    },
  },
  created() {
    this.setExamples();
  },
  beforeUnmount() {
    clearInterval(this.interval);
    if (this.resizeHandler)
      window.removeEventListener("resize", this.resizeHandler);
  },
  mounted() {
    this.setupInterval();
    this.$nextTick(() => this.scrollActiveToMiddle());
    this._registerResizeHandler();
  },
  methods: {
    clickHandler(_, i) {
      this.active = i;
      this.resetInterval();
    },
    onPauseAutoScroll() {
      this.pauseAutoScroll = true;
    },
    onResumeAutoScroll() {
      this.pauseAutoScroll = false;
      this.resetInterval;
    },
    setupInterval() {
      if (this.interval) clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (this.pauseAutoScroll) return;

        let next = (this.active + 1) % this.examples.length;
        if (next < SIDE_GAP) next = SIDE_GAP;
        if (next > this.examples.length - SIDE_GAP - 1) next = SIDE_GAP;
        this.active = next;
      }, SLIDE_INTERVAL);
    },
    resetInterval() {
      clearInterval(this.interval);
      this.setupInterval();
    },
    scrollActiveToMiddle() {
      const active = this.$refs.root?.children?.[this.active];
      active?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    _registerResizeHandler() {
      const resizeHandler = debounce(() => {
        this.scrollActiveToMiddle();
        this.resetInterval();
      }, 100);
      window.addEventListener("resize", resizeHandler);
      this.resizeHandler = resizeHandler;
    },
    setExamples() {
      const e = [...examples];
      e.sort(() => Math.random() - 0.5);

      this.active = SIDE_GAP;
      const gaps = new Array(SIDE_GAP).fill(null);
      this.examples = [...gaps, ...e, ...gaps];
    },
  },
};
</script>
