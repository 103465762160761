<template>
  <div
    :class="{
      'flex justify-end rounded-md': isUser,
      'flex justify-start': !isUser,
      'ml-9 w-full justify-start':
        messageType === 'tool_call' && searchResults !== null,
      'animate-pulse': messageStatus === 'sending',
    }"
    class="items-start gap-0 sm:gap-3"
  >
    <!-- AI Assistant Icon -->
    <template v-if="!isUser && !searchResults">
      <div class="hidden sm:block">
        <img
          alt="bi-robot"
          class="size-6 border-transparent"
          src="@/assets/icons/olx_logo.svg"
        />
      </div>
    </template>
    <div
      ref="content"
      :class="{
        'bg-blue-100 text-grey-700': isUser && !isFailed,
        'rounded-lg bg-grey-200 text-grey-700':
          !isUser && messageType !== 'tool_call',
        'rounded-lg bg-green-600 text-white':
          messageType === 'tool_call' && !searchResults,
        'rounded-2xl border-2 border-solid border-grey-300 bg-grey-300':
          messageType === 'tool_call' && searchResults !== null,
        'border border-red-600 bg-red-200 !py-0 text-red-800 opacity-70':
          isFailed,
      }"
      class="inline-block max-w-full rounded-lg px-2.5 py-1 text-base font-normal leading-6 sm:max-w-[90%]"
    >
      <Products
        v-if="products?.length"
        :products="products"
        :title="title"
        :message-timestamp="timestamp"
      />
      <div v-else class="message-text break-words">
        <Markdown :text="text" />
        <div v-if="shouldShowFeedback" class="flex h-3 gap-2">
          <template v-if="localFeedback === 'thumbs_up'">
            <div class="relative top-2 flex justify-start">
              <span>
                <img
                  alt="thumbs-up-fill-icon"
                  class="!m-0 size-6 !rounded-full border-transparent"
                  src="@/assets/icons/thumbs_up_fill.svg"
                />
              </span>
            </div>
          </template>
          <template v-else-if="localFeedback === 'thumbs_down'">
            <div class="relative top-2 flex justify-start">
              <span>
                <img
                  alt="thumbs-down-fill-icon"
                  class="!m-0 size-6 !rounded-full border-transparent"
                  src="@/assets/icons/thumbs_down_fill.svg"
                />
              </span>
            </div>
          </template>
          <template v-else>
            <div class="relative top-2 flex justify-start gap-2">
              <span
                v-track:click="MAGIC_THUMBS_UP"
                class="cursor-pointer"
                @click="updateFeedback('thumbs_up')"
              >
                <img
                  alt="thumbs-up-icon"
                  class="!m-0 size-6 !rounded-full border-transparent transition-transform hover:scale-110"
                  src="@/assets/icons/thumbs_up.svg"
                />
              </span>
              <span
                v-track:click="MAGIC_THUMBS_DOWN"
                class="cursor-pointer"
                @click="updateFeedback('thumbs_down')"
              >
                <img
                  alt="thumbs-down-icon"
                  class="!m-0 size-6 !rounded-full border-transparent transition-transform hover:scale-110"
                  src="@/assets/icons/thumbs_down.svg"
                />
              </span>
            </div>
          </template>
          <template v-if="isTextToSpeechEnabled && isLastMessage">
            <div class="relative top-2 flex justify-start">
              <span
                class="cursor-pointer"
                @mouseenter="toggleHover(true)"
                @mouseleave="toggleHover(false)"
                @click="audioState === 'play' ? pauseAudio() : resumeAudio()"
              >
                <img
                  alt="voice-icon"
                  class="!m-0 size-6 border-transparent"
                  :src="currentImage"
                />
              </span>
            </div>
          </template>
        </div>
      </div>
      <div v-if="filesUrls">
        <img
          v-for="(imageUrl, index) in filesUrls"
          :key="index"
          :src="imageUrl"
          :class="'message-image'"
          alt="input-image"
        />
      </div>
    </div>
    <!-- User Icon -->
    <template v-if="isUser">
      <div class="hidden sm:block">
        <img
          v-if="!isFailed"
          alt="user-icon"
          class="size-6 border-transparent"
          src="@/assets/icons/user_icon.svg"
        />
        <div
          v-else
          class="flex size-6 items-center justify-center rounded-full border border-red-700 bg-red-200 text-red-800"
        >
          !
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WebSocketService from "@/services/WebSocketService";
import Products from "./Products.vue";
import Markdown from "../../ui/Markdown.vue";
import {
  MAGIC_THUMBS_DOWN,
  MAGIC_THUMBS_UP,
} from "@/constants/eventsConstants";

export default {
  name: "Message",
  components: {
    Products,
    Markdown,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
    isUser: Boolean,
    filesUrls: {
      type: Array,
      default: () => [],
    },
    messageType: {
      type: String,
      default: "text",
    },
    products: {
      type: Array,
      default: () => [],
    },
    feedback: {
      type: String,
      default: "",
    },
    searchResults: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    messageStatus: {
      type: String,
      default: "",
    },
    isContextualEntryMessage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:feedback"],
  data() {
    return {
      localFeedback: this.feedback,
      isHovered: false,
      MAGIC_THUMBS_DOWN,
      MAGIC_THUMBS_UP,
    };
  },
  computed: {
    currentImage() {
      switch (true) {
        case this.isHovered && this.audioState === "play":
          return require("@/assets/icons/audio_stop.svg");
        case !this.isHovered && this.audioState === "play":
          return require("@/assets/gifs/voice.gif");
        case this.isHovered && this.audioState === "pause":
          return require("@/assets/icons/audio_play.svg");
        default:
          return require("@/assets/icons/audio_pause.svg");
      }
    },
    ...mapState("settings", ["isTextToSpeechEnabled"]),
    ...mapState("messages", ["messages", "audioState"]),
    isLastMessage() {
      if (!this.messages) {
        return false;
      }
      return (
        this.messages.length > 0 &&
        this.messages[this.messages.length - 1].text === this.text
      );
    },
    isFailed() {
      return this.messageStatus === "failed";
    },
    shouldShowFeedback() {
      return (
        !this.isUser && !this.searchResults && !this.isContextualEntryMessage
      );
    },
  },
  watch: {
    feedback(newVal) {
      this.localFeedback = newVal;
    },
  },
  methods: {
    updateFeedback(feedback) {
      this.localFeedback = feedback;
      this.$emit("update:feedback", feedback);
      this.$store.dispatch("messages/updateMessageFeedback", {
        message: this.text,
        feedback,
        type: "text_results",
      });
    },
    toggleHover(value) {
      this.isHovered = value;
    },
    pauseAudio() {
      this.$store.dispatch("messages/updateAudioState", "pause");
      WebSocketService.pauseAudio();
    },
    resumeAudio() {
      this.$store.dispatch("messages/updateAudioState", "play");
      WebSocketService.resumeAudio();
    },
  },
};
</script>

<style scoped>
.message-text {
  font-family: "Geomanist", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.message-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 10px;
  margin-top: 10px;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
