<template>
  <button
    v-track:click="
      isMagicHybrid()
        ? $EVENTS?.HYBRID?.MAGIC_DOWNLOAD_APP_CLICK
        : $EVENTS?.CORE?.MAGIC_DOWNLOAD_APP_CLICK
    "
    :class="
      $twMerge(
        'flex w-full items-center gap-2 px-4',
        'py-2 text-sm text-gray-700 hover:bg-gray-100',
      )
    "
    @click="onClick"
  >
    <img
      :class="$twMerge('text-black')"
      src="@/assets/icons/download.svg"
      alt="Back Icon"
    />
    <div class="mt-1">
      {{ $t("navbar.download") }}
    </div>
  </button>
</template>

<script>
import { mapMutations } from "vuex";
import { isSearchRoute } from "../product-page/utils";

export default {
  name: "DownloadButtonTrigger",
  emits: ["click"],
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    ...mapMutations("floatingItems", ["SET_DOWNLOAD_APP_VISIBLE"]),
    onClick() {
      this.SET_DOWNLOAD_APP_VISIBLE(true);
      this.$emit("click");
    },
    isMagicHybrid() {
      return isSearchRoute();
    },
  },
};
</script>
