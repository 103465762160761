<template>
  <Component
    :is="tag"
    v-if="link"
    v-track:click="{
      ...MAGIC_AD_LINK,
      ad_id: id,
      source: 'recommendation',
    }"
    :href="link"
    target="_blank"
    class="w-full"
  >
    <BaseButton class="w-full" variant="primary-dark">
      {{ $t("product.buy_button") }}
    </BaseButton>
  </Component>
</template>

<script>
import { getAd } from "@/services/AdsService";
import BaseButton from "@/components/ui/BaseButton.vue";
import { RouterLink } from "vue-router";
import { MAGIC_AD_LINK } from "@/constants/eventsConstants";

export default {
  name: "MarkdownLink",
  components: {
    BaseButton,
    RouterLink,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      link: null,
      tag: "a",
      MAGIC_AD_LINK,
    };
  },
  mounted: async function () {
    try {
      const ad = await getAd(this.id);
      const link = ad.url.split("?")[0];
      this.link = `${link}?reason=magic&ad_reason=magic`;
      this.tag = "a";
    } catch {
      this.link = `/buy/${this.id}`;
      this.tag = "RouterLink";
    }
  },
};
</script>
