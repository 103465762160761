const NOT_ALLOWED_VALUES = ["null", "undefined"];

export function filterEntryQueryParams(params) {
  const clonedParams = {};

  Object.entries(params).forEach(([key, value]) => {
    if (!value) return;
    else if (NOT_ALLOWED_VALUES.includes(value)) return;
    else if (key.startsWith("_")) return;
    else if (key.length < 3) return;
    else if (["sessionId", "session_id"].includes(key)) return;

    clonedParams[key] = value;
  });
  return clonedParams;
}
