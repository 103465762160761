const defaultState = () => ({
  mainSummary: null,
  clarifyingQuestion: "",
  clarifyingQuestionSuggestions: [],
  mainError: null,
});
const state = defaultState();

const mutations = {
  SET_SEARCH_MAGIC_SUMMARY(state, summary) {
    state.mainSummary = summary;
  },
  SET_CLARIFYING_QUESTION(state, question) {
    state.clarifyingQuestion = question;
  },
  SET_CLARIFYING_QUESTION_SUGGESTIONS(state, suggestions) {
    state.clarifyingQuestionSuggestions = suggestions;
  },
  CLEAR_FOR_NEW_SEARCH(state) {
    Object.assign(state, defaultState());
  },
  SET_MAIN_ERROR(state, error) {
    state.mainError = error;
  },
};

const actions = {};

const getters = {
  mainSummary: (state) => state.mainSummary,
  clarifyingQuestion: (state) => state.clarifyingQuestion,
  clarifyingQuestionSuggestions: (state) => state.clarifyingQuestionSuggestions,
  mainError: (state) => state.mainError,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
