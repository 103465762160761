<template>
  <div class="my-2 w-full border-y border-grey-400 bg-teal-200 p-4">
    <h2
      class="mb-1 flex items-center gap-1 font-medium uppercase tracking-widest text-grey-700"
    >
      <TitleLead class="mb-1 mr-1 inline" />
      <div class="mb-1 h-6 w-60 animate-pulse rounded bg-gray-300"></div>
    </h2>
    <div class="flex w-full gap-2 overflow-x-auto">
      <div
        v-for="n in 5"
        :key="n"
        class="w-40 rounded-lg border border-gray-200 bg-white"
      >
        <div class="relative">
          <div class="size-40 animate-pulse rounded-t-md bg-gray-300"></div>
          <div
            class="absolute left-2 top-2 h-6 w-10 animate-pulse rounded bg-gray-300 px-2 py-1 text-xs font-bold text-white"
          ></div>
        </div>
        <div class="px-3 py-2">
          <div class="h-4 w-3/4 animate-pulse rounded bg-gray-300"></div>
          <div class="mt-2 h-4 w-1/2 animate-pulse rounded bg-gray-300"></div>
          <div class="mt-4 h-8 w-3/4 animate-pulse rounded bg-gray-300"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleLead from "./icons/TitleLead.vue";
export default {
  name: "ProductGroupSkeleton",
  components: {
    TitleLead,
  },
};
</script>
