import router from "@/router";

export function isFromMagicHybrid() {
  const route = router.currentRoute.value;
  return route.query?.from === "magic_hybrid";
}

export function isSearchRoute() {
  const route = router.currentRoute.value;
  return route.path === "/search";
}
