import { verifyMagicToken } from "@/services/AuthService";
import { auth0, getAccessToken as auth0GetAccessToken } from "./auth_config";
import {
  AuthOLX,
  authGuard as olxAuthGuard,
  getAccessToken as oxlGetAccessToken,
} from "./auth_olx";
import { getMagicToken } from "./magic_auth";

const auth0Domain = process.env.VUE_APP_AUTH0_DOMAIN;
const isOlx = !!auth0Domain?.includes(".olx");

export const authPlugin = isOlx ? AuthOLX : auth0;

/**
 * Get the access token from the current auth provider.
 * - Try to get the access token from Auth0 or OLX
 * - If the user is not authenticated, try to get the access token from Magic
 * @returns {Promise<string>}
 */
export async function getAccessToken() {
  let token = null;
  try {
    token = isOlx ? await oxlGetAccessToken() : await auth0GetAccessToken();
  } catch {
    token = getMagicToken();
  }
  return token;
}

/**
 * A dynamic auth guard that checks the current auth provider.
 * - Try to authenticate with Auth0 or OLX
 * - If the user is not authenticated, try to authenticate with Magic
 * @param {string} to
 * @returns
 */
export async function authGuard(to) {
  let authResult = false;
  try {
    authResult = isOlx ? await olxAuthGuard(to) : await auth0AuthGuard(to);
  } catch {
    //noop
  }

  if (!authResult) {
    try {
      const token = getMagicToken();
      await verifyMagicToken(token);
      authResult = true;
    } catch {
      authResult = false;
    }
  }

  if (!authResult) window.location.href = "/login";
  return authResult;
}

async function auth0AuthGuard() {
  try {
    const token = await auth0GetAccessToken();
    return !!token;
  } catch {
    return false;
  }
}
