import TrackingService from "./../services/TrackingService";
import logger from "@/services/logger";

const TrackingPlugin = {
  install(app) {
    if (
      Object.prototype.hasOwnProperty.call(
        app.config.globalProperties,
        "$tracking",
      )
    ) {
      logger.warn(
        "Vue $tracking property already exists. Make sure you are not installing the plugin multiple times.",
      );
      return;
    }
    app.config.globalProperties.$tracking = TrackingService;
    app.provide("tracking", TrackingService);
  },
};

export default TrackingPlugin;
