const abortControllers = {};

export function handleCancelableRequest(url, method, namespace) {
  const key = getAbortControllerKey(url, method);
  cancelAbortController(key, namespace);

  const controller = new AbortController();
  addAbortController(key, controller, namespace);

  return controller.signal;
}

export function addAbortController(key, controller, namespace) {
  if (namespace) {
    abortControllers[namespace] = abortControllers[namespace] || {};
    abortControllers[namespace][key] = controller;
  } else {
    abortControllers[key] = controller;
  }
}

export function cancelAbortController(key, namespace) {
  if (namespace) {
    abortControllers?.[namespace]?.[key]?.abort();
    delete abortControllers?.[namespace]?.[key];
  } else {
    abortControllers[key]?.abort();
    delete abortControllers[key];
  }
}

export function getAbortControllerKey(url, method) {
  return `${url}_${method}`;
}

export function abortAllControllersByNamespace(namespace) {
  if (abortControllers[namespace]) {
    Object.values(abortControllers[namespace]).forEach((key) => {
      cancelAbortController(key, namespace);
    });
  }
}
