<template>
  <div v-if="tags?.length" class="flex flex-col gap-0.5">
    <TransitionGroup name="slide-down" duration="100">
      <SearchTag
        v-for="(tag, index) in tags"
        :key="tag.label"
        :style="{ transitionDelay: `${index * 50}ms` }"
        class="transition-all duration-100"
        :class="{
          'mb-3': index === tags.length - 1,
        }"
        :icon="tag.icon"
        @remove="onRemove(tag)"
      >
        <Markdown :text="tag.label" />
        <template v-if="tag.image" #image-preview>
          <img
            :src="tag.image"
            class="ml-auto size-8 shrink-0 rounded-md object-cover"
            alt="Preview"
          />
        </template>
      </SearchTag>
    </TransitionGroup>
  </div>
</template>
<script>
import { moveToNewSearch } from "@/store/utils/hybrid";
import { mapActions, mapGetters } from "vuex";
import SearchTag from "./SearchTag.vue";
import Markdown from "@/components/ui/Markdown.vue";

export default {
  name: "SearchBarTags",
  components: {
    SearchTag,
    Markdown,
  },
  computed: {
    ...mapGetters("search", [
      "queryText",
      "queryTags",
      "queryImage",
      "visualSearchInputs",
      "originProduct",
    ]),
    tags: function () {
      const vsTag = this.visualSearchInputs?.search_query;
      const similarProduct = this.originProduct;

      const queryTextTag = this.queryText
        ? {
            label: this._key("query", this.queryText),
            type: "query-text",
            icon: "search",
          }
        : undefined;
      const visualTag = vsTag
        ? {
            label: this._key("query_image", vsTag),
            type: "query-image",
            image: this.queryImage,
            icon: "image",
          }
        : undefined;
      const productTag = similarProduct
        ? {
            label: this._key("product", similarProduct.title),
            type: "query-product",
            image: similarProduct.photos?.[0]?.url,
            icon: "image",
          }
        : undefined;

      return [
        productTag || visualTag || queryTextTag,
        ...this.queryTags.map((label) => ({ label, type: "query-tag" })),
      ].filter((tag) => !!tag);
    },
  },
  methods: {
    ...mapActions("search", ["removeQueryTag", "reset"]),
    onRemove({ label, type } = {}) {
      if (type === "query-image" || type === "query-product") {
        moveToNewSearch({ newQuery: this.queryText, newTags: this.queryTags });
      } else if (type === "query-text") {
        this.reset();
        moveToNewSearch({});
      } else {
        this.removeQueryTag(label);
      }
    },
    _key(tag, value) {
      const label = this.$t(`search.search_bar.tag_key_${tag}`);
      return `${label}: ${value}`;
    },
  },
};
</script>

<style scoped>
::v-deep .markdown-p {
  margin: 0 !important;
}
</style>
