<template>
  <ActionsBar />
  <RefineModal />
  <SmartCompareModal />
</template>

<script>
import ActionsBar from "./actions/ActionsBar.vue";
import RefineModal from "./actions/RefineModal.vue";
import SmartCompareModal from "./actions/SmartCompareModal.vue";

export default {
  components: {
    RefineModal,
    ActionsBar,
    SmartCompareModal,
  },
};
</script>
