<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M12 4L4 12" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4 4L12 12" stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
</template>
