import { getItem, setItem } from "@/utils/storage";
import store from "@/store";
import TrackingService from "../../services/TrackingService";
import { MAGIC_SEARCH_TEXT } from "@/constants/eventsConstants";

export function isOnboardingItemSeen(onboardingType) {
  return getItem(getKey(onboardingType));
}

export function setOnboardingItemSeen(onboardingType, value) {
  setItem(getKey(onboardingType), value);
}

export function checkAndTriggerOnboarding(onboardingType) {
  const messages = store.state.messages.messages;
  const isSeen = isOnboardingItemSeen(onboardingType);
  if (isSeen) return false;

  switch (onboardingType) {
    case "new_conversation":
      if (messages.filter((item) => item.isUser).length >= 4) {
        triggerOnboardingTip("new_conversation");
        return true;
      }
      break;

    case "input_box":
      if (messages.filter((item) => item.products?.length > 0).length >= 1) {
        triggerOnboardingTip(onboardingType);
        return true;
      }
      break;

    case "select_smart_compare":
      if (messages.filter((item) => item.products?.length > 0).length >= 2) {
        triggerOnboardingTip(onboardingType);
        return true;
      }
      break;

    default:
      break;
  }

  return false;
}

export function triggerVisualSearch() {
  const isSeen = isOnboardingItemSeen("visual_search");
  if (!isSeen) triggerOnboardingTip("visual_search");
}

export function triggerMessageTrackingEvent(conversation_id) {
  TrackingService.trackEvent("click", {
    ...MAGIC_SEARCH_TEXT,
    conversation_id,
  });
}

export function removePrefix(text, prefix) {
  return text.startsWith(prefix) ? text.slice(prefix.length) : text;
}

function isAnyOnboardingVisible() {
  return Object.values(store.state.floatingItems.onboardingTipVisible).some(
    (v) => v,
  );
}

function triggerOnboardingTip(onboardingType) {
  if (isAnyOnboardingVisible()) return;

  store.dispatch("floatingItems/showOnboardingTip", {
    onboardingType,
  });
  setOnboardingItemSeen(onboardingType, true);
}

function getKey(onboardingType) {
  return `onboarding_${onboardingType}`;
}
