export const examples = [
  {
    text: "Upload a photo to find similar item",
    locale_text: "cards.card1",
    type: "camera",
    src: require("@/assets/icons/image.svg"),
  },
  {
    text: "Use the microphone to describe what you want",
    locale_text: "cards.card2",
    type: "mic",
    src: require("@/assets/icons/microphone.svg"),
    icon: "io-phone-portrait-outline",
  },
  {
    text: "Ask for trend or product information",
    locale_text: "cards.card3",
    type: "text",
    src: require("@/assets/icons/watch.svg"),
    icon: "io-game-controller-outline",
    prompt_text_label: "example.card3.prompt_text",
    prompt_key_label: "example.card3.prompt_key",
  },
  {
    text: "Chat to discover personalised information",
    locale_text: "cards.card4",
    type: "text",
    src: require("@/assets/icons/chat.svg"),
    icon: "bi-laptop",
    prompt_text_label: "example.card4.prompt_text",
    prompt_key_label: "example.card4.prompt_key",
  },
  {
    text: "Find the best deals",
    locale_text: "cards.card5",
    type: "text",
    src: require("@/assets/icons/deal.svg"),
    icon: "bi-gift",
    prompt_text_label: "example.card5.prompt_text",
    prompt_key_label: "example.card5.prompt_key",
  },
  {
    text: "Ask for inspiration ...",
    locale_text: "cards.card6",
    type: "text",
    src: require("@/assets/icons/idea.svg"),
    icon: "bi-search",
    prompt_text_label: "example.card6.prompt_text",
    prompt_key_label: "example.card6.prompt_key",
  },
];
