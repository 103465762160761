import { MODULE_NAMES } from "./persisted-modules";
import { getModulePersistanceKey } from "./persistance";
import logger from "@/services/logger";

export function loadPersistedData(store) {
  MODULE_NAMES.forEach((moduleName) => {
    try {
      const key = getModulePersistanceKey(moduleName);
      const persistedData = localStorage.getItem(key);

      if (persistedData) {
        store.commit(`${moduleName}/LOAD_DATA`, JSON.parse(persistedData));
      }
    } catch (e) {
      logger.error(`Error loading persisted data for ${moduleName}`, e);
    }
  });
}

// TO DO CHANGE THE NAME OF THIS FUNCTION
export async function registerCookieConsentListener(store) {
  loadPersistedData(store);
}
