import DOMPurify from "dompurify";

export function sentenceCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function camelToSnake(camelCaseString) {
  return camelCaseString.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`,
  );
}

export function sanitizeHTML(str) {
  return DOMPurify.sanitize(str);
}

export function sanitizeMessage(content) {
  // Convert <br> and <div> tags to newlines
  let sanitized = content
    .replace(/<br\s*\/?>/gi, "\n")
    .replace(/<div>/gi, "\n")
    .replace(/<\/div>/gi, "");

  // Remove all HTML tags
  sanitized = sanitized.replace(/<[^>]*>/g, "");

  // Remove continuous newlines (more than 2)
  sanitized = sanitized.replace(/\n{3,}/g, "\n\n");

  // Trim leading and trailing whitespace
  sanitized = sanitized.trim();
  return sanitized;
}
