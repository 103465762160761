<template>
  <Transition name="slide-left" duration="300">
    <div
      v-if="mainError"
      class="flex flex-col items-start text-sm transition-all duration-300 ease-in-out"
    >
      <MagicHelperBubble variant="error">
        <Markdown :text="mainError" />

        <div class="mt-3 flex w-full items-center">
          <BaseButton class="ml-auto" variant="error" @click="onRetry">
            {{ $t("search.helper.main_error_retry") }}
          </BaseButton>
          <BaseButton class="ml-2" variant="error" @click="onClear">
            {{ $t("search.helper.main_error_clear") }}
          </BaseButton>
        </div>
      </MagicHelperBubble>
    </div>
  </Transition>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import Markdown from "@/components/ui/Markdown.vue";
import { mapActions, mapGetters } from "vuex";
import MagicHelperBubble from "./MagicHelperBubble.vue";

export default {
  name: "MagicHelperError",
  components: {
    Markdown,
    MagicHelperBubble,
    BaseButton,
  },
  computed: {
    ...mapGetters("hybridHelper", ["mainError"]),
  },
  methods: {
    ...mapActions("search", ["reset"]),
    onRetry() {
      // hard refresh
      location.reload(true);
    },
    onClear() {
      this.reset();
      this.$router.push({ name: "Search" });
    },
  },
};
</script>
