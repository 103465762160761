<template>
  <div class="flex flex-col gap-4 rounded-md bg-blue-500 p-4 text-white">
    <p>{{ $t(tooltipText) }}</p>
    <button
      :class="
        $twMerge(
          'cursor-pointer text-right text-sm font-medium leading-6',
          'text-white underline decoration-2 underline-offset-4',
        )
      "
      @click="handleButtonClick"
    >
      {{ $t("tour_tooltip_text.button_text") }}
    </button>
  </div>
</template>

<script>
import { removePrefix } from "@/store/utils/onboarding";
import store from "@/store";

export default {
  name: "DropdownPopper",
  props: {
    hide: {
      type: Function,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.autoHideTimeout = setTimeout(() => {
      this.hide();
    }, 10000);
  },
  beforeUnmount() {
    const prefix = "tour_tooltip_text.";
    const cleanedType = removePrefix(this.tooltipText, prefix);
    store.dispatch("floatingItems/hideOnboardingTip", {
      onboardingType: cleanedType,
    });
    clearTimeout(this.autoHideTimeout);
  },
  methods: {
    handleButtonClick() {
      clearTimeout(this.autoHideTimeout);
      this.hide();
    },
  },
};
</script>
