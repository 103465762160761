<template>
  <Transition name="fade">
    <div
      v-if="visible"
      class="pointer-events-none fixed inset-x-0 bottom-20 md:bottom-28"
    >
      <div class="container mx-auto flex p-0 md:p-4">
        <div class="ml-auto flex w-full justify-center">
          <span class="pointer-events-auto" @click="$emit('click')">
            <img
              alt="arrow_down-icon"
              class="m-0 size-10 border-0 border-transparent"
              src="@/assets/icons/arrow_down.svg"
            />
          </span>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "ScrollToBottomButton",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
};
</script>
