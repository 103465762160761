<template>
  <div class="flex w-full flex-col gap-2 rounded">
    <div class="flex items-center justify-between md:hidden">
      <h2 class="text-base font-medium leading-5">
        {{ $t("magic_search_two_point.modal.heading") }}
      </h2>
    </div>
    <div class="relative flex rounded bg-white">
      <input
        ref="messageInput"
        v-model="message"
        type="text"
        :placeholder="$t('magic_search_two_point.modal.placeholder_one')"
        :class="[
          'w-full items-center rounded border-none px-4 py-2 focus:outline-none focus:ring-0',
          'text-base font-normal leading-5',
        ]"
        @keyup.enter="onRefineSearch"
      />
      <button class="px-3 py-2" @click="onRefineSearch">
        <FilterIcon class="size-4" />
      </button>
    </div>
    <div
      class="olx-horizontal-scrollbar flex gap-2 overflow-x-auto pb-2 pr-4 md:hidden"
    >
      <BaseButton
        v-for="option in refineSuggestions"
        :key="option"
        v-track:click="$EVENTS?.HYBRID?.MAGIC_FILTER_SELECTED"
        variant="primary"
        class="shrink-0"
        @click="onRefine($event, option)"
      >
        {{ option }}
      </BaseButton>
    </div>
    <h2 class="text-sm font-normal leading-[14px] text-grey-500">
      {{ $t("magic_search_two_point.modal.info_filter") }}
    </h2>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BaseButton from "@/components/ui/BaseButton.vue";
import FilterIcon from "@/components/ui/icons/FilterIcon.vue";

export default {
  name: "RefineContent",
  components: { BaseButton, FilterIcon },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    ...mapGetters("hybridActions", ["refineSuggestions"]),
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_REFINE_MODAL_OPEN"]),
    ...mapActions("hybridActions", ["refine"]),
    onRefineSearch(event, message) {
      this.$tracking.trackEvent(
        "click",
        this.$EVENTS?.HYBRID?.MAGIC_FILTER_INPUT,
      );
      this.onRefine(event, message);
    },
    onRefine(event, message) {
      this.refine({ message: message ?? this.message });
    },
  },
};
</script>
