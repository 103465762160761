export default {
  namespaced: true,
  state: {
    products: [],
  },
  mutations: {
    ADD_PRODUCTS(state, products) {
      const uniqueProducs = [...state.products, ...products].filter(
        (product) => {
          return !state.products.some((p) => p.id === product.id);
        },
      );
      state.products = uniqueProducs;
    },
  },
  getters: {
    products: (state) => state.products,
    productById: (state) => (id) => {
      return state.products.find(
        (product) => product.id.toString() === id.toString(),
      );
    },
  },
};
