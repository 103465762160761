import { authPlugin } from "@/auth";
import * as Sentry from "@sentry/vue";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { twMerge } from "tailwind-merge";
import { createApp } from "vue";
import LoadScript from "vue-plugin-load-script";
import "./animations.css";
import App from "./App.vue";
import "./assets/fonts/fonts.css";
import "./index.css";
import "./markdown.css";
import TrackingPlugin from "./plugins/tracking-plugin";
import router from "./router";
import store from "./store";
import "./transitions.css";
import scrollToElement from "./utils/scrollToElement";
import { trackDirective } from "./utils/trackDirective";
import { EVENTS } from "@/constants/eventsConstants";

import {
  BiChevronDoubleDown,
  BiGift,
  BiHeart,
  BiHeartFill,
  BiImages,
  BiInfoCircle,
  BiLaptop,
  BiRobot,
  BiSearch,
  BiWebcam,
  BiX,
  FaPhotoVideo,
  FaRegularEye,
  FaUserAlt,
  GiBrainstorm,
  IoGameControllerOutline,
  IoPhonePortraitOutline,
  OiChevronLeft,
  OiChevronRight,
  RiVoiceprintFill,
} from "oh-vue-icons/icons";

import DropdownPopper from "./components/navbar/DropdownPopper.vue";
import "./registerServiceWorker";
import { injectAxiosInterceptors } from "@/services/interceptors";
import { i18n } from "@/locale/i18n";

addIcons(
  FaPhotoVideo,
  FaRegularEye,
  RiVoiceprintFill,
  BiSearch,
  GiBrainstorm,
  IoPhonePortraitOutline,
  IoGameControllerOutline,
  BiLaptop,
  BiGift,
  BiRobot,
  FaUserAlt,
  BiInfoCircle,
  BiHeart,
  BiHeartFill,
  BiX,
  BiImages,
  OiChevronLeft,
  OiChevronRight,
  BiChevronDoubleDown,
  BiWebcam,
);

injectAxiosInterceptors();

const app = createApp(App);

app.config.devtools = true;

app.config.globalProperties.$twMerge = twMerge;
app.config.globalProperties.$EVENTS = EVENTS;

app.component("VIcon", OhVueIcon);
app.directive("track", trackDirective);
app.directive("scroll-to-element", scrollToElement);

// TODO figure out why this is needed
app.component("DropdownPopper", DropdownPopper);

app.use(TrackingPlugin);
app.use(LoadScript);
app.use(authPlugin);
app.use(i18n);
app.use(router);
app.use(store);
app.use(FloatingVue);

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [/^https:\/\/ai\.olx\.org\//],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

store.watch(
  (state) => state.settings.selectedLocale,
  (newLocale) => (i18n.global.locale = newLocale),
);

app.mount("#app");
