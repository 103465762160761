<template>
  <div :class="$twMerge('fixed inset-0 z-50 flex items-center justify-center')">
    <div
      class="backdrop absolute inset-0 bg-black/50"
      @click="$emit('close')"
    ></div>
    <div class="modal fixed bottom-0 right-0 z-10 bg-white shadow-lg">
      <slot></slot>
      <button
        :class="
          $twMerge(
            'absolute right-6 top-4 flex size-12',
            'items-center justify-center rounded-lg',
            'hover:bg-gray-200',
          )
        "
        @click="$emit('close')"
      >
        <img
          :class="$twMerge('size-6')"
          src="@/assets/icons/close.svg"
          alt="close-icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideModal",
  emits: ["close"],
};
</script>
<style scoped>
.modal {
  height: calc(100% - 4.5rem);
  width: 28rem;
}
.backdrop {
  backdrop-filter: blur(2px);
}
</style>
