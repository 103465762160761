export function createVirtualDOM(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const rootNode = doc.body;

  function createNode(element) {
    const node = {
      tagName: element.tagName,
      attributes: {},
    };

    for (let i = 0; i < element.attributes.length; i++) {
      const attribute = element.attributes[i];
      node.attributes[attribute.name] = attribute.value;
    }

    if (element.childNodes.length > 0) {
      node.children = [];
      for (let i = 0; i < element.childNodes.length; i++) {
        const child = element.childNodes[i];
        if (child.nodeType === Node.ELEMENT_NODE) {
          node.children.push(createNode(child));
        } else if (child.nodeType === Node.TEXT_NODE) {
          node.children.push({
            tagName: null,
            text: child.textContent,
          });
        }
      }
    }

    return node;
  }

  return createNode(rootNode);
}
