<template>
  <div>
    <h1
      class="inline-block bg-text-gradient bg-clip-text text-4xl font-medium text-transparent"
    >
      {{ greeting }}
    </h1>
    <p class="hidden py-1 text-lg font-normal text-grey-600 sm:block">
      {{ $t("conversation.sub_head") }}
    </p>
    <p class="py-4 text-sm font-normal text-grey-600">
      {{ $t("conversation.description") }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ConversationHeader",
  computed: {
    ...mapGetters("user", ["userName"]),
    greeting() {
      const username = this.userName;
      return (
        this.$t("conversation.greeting") + (username ? ` ${username},` : ",")
      );
    },
  },
};
</script>
