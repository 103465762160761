<template>
  <img
    src="@/assets/icons/microphone_white.svg"
    class="size-6 animate-pulse"
    alt="Microphone Icon"
  />
</template>
<script>
export default {
  name: "Microphone",
};
</script>
